import { Logger } from '@rover/rsdk/src/modules/Logging';
import ObservabilityMetadata from '@rover/rsdk/src/modules/ObservabilityMetadata';

import type { EmitMetricOptions } from './emitMetricTypes';

export default function emitMetric(
  metricName: string,
  tags: Record<string, string> = {},
  options: EmitMetricOptions = {}
): void {
  const { sampleRate = 1, metricType = 'increment', metricValue = 1 } = options;
  if (process.env.JS_ENV_CLIENT) {
    if (window.alooma) {
      if (Math.random() <= sampleRate) {
        const { concept, locale, team, uiComponentName, uiName } = ObservabilityMetadata.data();
        window.alooma.track(metricName, {
          observability_metric_type: metricType,
          observability_metric_value: metricValue,
          observability_tags: {
            ...tags,
            concept,
            locale,
            team,
            uiComponentName,
            uiName,
          },
          observability_only: true,
        });
      }
    } else {
      Logger.info(`window.alooma is not defined, cannot emit metric: ${metricName}`);
    }
  }
}
