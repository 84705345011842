import { KeyboardEvent } from 'react';

export function onSpecifiedKeyPress(
  e: KeyboardEvent,
  keys: string[],
  callback: () => unknown
): void {
  if (keys.includes(e.key)) {
    e.preventDefault();
    callback();
  }
}

export function onSpaceOrEnterKeyPress(e: KeyboardEvent, callback: () => unknown): void {
  onSpecifiedKeyPress(e, ['Enter', ' '], callback);
}

export function onEnterKeyPress(e: KeyboardEvent, callback: () => unknown): void {
  onSpecifiedKeyPress(e, ['Enter'], callback);
}
