import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';

import { Day } from '@rover/types';
import { I18nType } from '@rover/rsdk/src/modules/I18n';
import type { Time } from '@rover/types/src/datetime/Time';

import { getTimeFormat } from '../factories/timeFactory';

// checks if two Date objects are on the same day, regardless of time.
export function isSameDay(a: Date, b: Date): boolean {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  );
}

export function isToday(a: Date): boolean {
  return isSameDay(a, new Date());
}

// NOTE(WARNING): date is also modified in place!
export function addDaysToDate(date: Date, k: number): Date {
  date.setDate(date.getDate() + k);
  return date;
}

export function getTomorrow(): Date {
  const date = new Date();
  addDaysToDate(date, 1);
  return date;
}

export function convertTimeTo24Hour(time: Time | undefined): string | null {
  if (!time || !time.value) {
    return null;
  }
  const units = time.value.split(/[ :]/);
  let hour = units[0];
  const minute = units[1];
  if (units.length > 2) {
    const period = units[2].toLowerCase();
    if (hour !== '12' && period === 'pm') {
      hour = (parseInt(units[0], 10) + 12).toString();
    } else if (hour === '12' && period === 'am') {
      hour = (parseInt(units[0], 10) - 12).toString();
    }
  }
  return [hour, minute].join(':');
}

export function getStringFromDates<T extends Day>(selectedDays: T[]): string | MessageDescriptor {
  const numberOfDays = selectedDays.length;
  if (numberOfDays === 1) {
    const firstDay = moment(selectedDays[0].date);
    return firstDay.format('ddd, DD MMM YYYY');
  }
  if (numberOfDays > 7) {
    return t`${numberOfDays} days selected`;
  }
  if (numberOfDays > 1) {
    const firstDay = moment(selectedDays[0].date);
    const firstDayMonth = firstDay.month();
    const lastDay = moment(selectedDays[selectedDays.length - 1].date);
    const lastDayMonth = lastDay.month();
    if (firstDayMonth === lastDayMonth) {
      const days: number[] = [];
      selectedDays.forEach((day) => {
        const dayDate = moment(day.date);
        days.push(dayDate.date());
      });
      return `${days.join(', ')} ${firstDay.format('MMM YYYY')}`;
    }
    return t`${numberOfDays} days selected`;
  }
  return '';
}

/**
 * Checks if the given date and time are in the past.
 * @param date the date to check
 * @param time in the format of 'hh:mm A' or 'HH:mm'
 * @returns true if the given date and time are in the past, false otherwise.
 */
export function isTimeInThePastForToday(date: Date, time?: string): boolean {
  if (!date) {
    return false;
  }
  const dateMoment = moment(date);
  const now = moment();

  if (dateMoment.isSame(now, 'day')) {
    const timeMoment = moment(time, getTimeFormat());
    return timeMoment.isValid() ? timeMoment.isBefore(now) : false;
  }

  return false;
}

export function computeAriaLabelFromDays(
  days: Day[],
  i18n: I18nType,
  DF: Record<string, string>
): string | null {
  if (!days || days.length === 0) {
    return null;
  }
  let isSameMonth = true;
  let isSameYear = true;
  const firstDay = moment(days[0].date);
  const firstDayMonth = firstDay.month();
  const firstDayYear = firstDay.year();
  days.forEach((day) => {
    const dayDate = moment(day.date);
    if (dayDate.month() !== firstDayMonth) {
      isSameMonth = false;
    }
    if (dayDate.year() !== firstDayYear) {
      isSameYear = false;
    }
  });
  if (isSameMonth && isSameYear) {
    return null;
  }

  return i18n._(
    t`Selected dates are ${days.map((day) => moment(day.date).format(DF.DATE_FULL)).join(', ')}`
  );
}

const dateTimeUtils = {
  isSameDay,
  getTomorrow,
  addDaysToDate,
  convertTimeTo24Hour,
  isTimeInThePastForToday,
};

export default dateTimeUtils;
