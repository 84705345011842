import { ComponentClass } from 'react';

export type LiftedProps<State> = {
  state: State;
  onLiftedChange: (nextState: State) => void;
};

export type LiftedComponentProps<Props, State> = Props & LiftedProps<State>;

export type LiftedComponentClass<
  Props extends Record<string, unknown>,
  State extends Record<string, unknown>
> = ComponentClass<LiftedComponentProps<Props, State>> & {
  initialState: State;
};

export const lifted = <
  Props extends Record<string, unknown>,
  State extends Record<string, unknown>
>(
  WrappedComponent: ComponentClass<Props, State>
): LiftedComponentClass<Props, State> =>
  // @ts-expect-error
  class LiftedComponent extends WrappedComponent {
    static initialState: State;

    // @ts-expect-error
    props: LiftedComponentProps<Props, State>;

    static displayName = `Lifted${WrappedComponent.displayName || WrappedComponent.name || ''}`;

    initialized = false;

    // @ts-expect-error
    get state(): State {
      return (this.props && this.props.state) || LiftedComponent.initialState;
    }

    set state(value: State) {
      if (!this.initialized && value) {
        this.initialized = true;
        LiftedComponent.initialState = value;
        this.props.onLiftedChange(value);
      }
    }

    componentDidMount() {
      if (super.componentDidMount) super.componentDidMount();

      if (this.props.state) {
        this.props.onLiftedChange(this.props.state);
      }
    }

    setState(reducer, callback) {
      if (typeof reducer === 'function' && this.props.onLiftedChange) {
        this.props.onLiftedChange(reducer(this.state, this.props));
      } else if (this.props.onLiftedChange) {
        this.props.onLiftedChange(reducer);
      }

      if (callback) callback();
    }
  };
