/* eslint-disable no-plusplus, no-underscore-dangle */
import React, { ComponentClass } from 'react';
import { Reducer } from 'redux';

import Connect from '@rover/react-lib/src/components/utils/Redux/Connect';
import { lifted as liftedCore } from '@rover/react-lib/src/lib/lifted-components/core';

export const MOUNT_LIFTED_COMPONENT = '@lifted-components/MOUNT_LIFTED_COMPONENT' as const;
export const UNMOUNT_LIFTED_COMPONENT = '@lifted-components/UNMOUNT_LIFTED_COMPONENT' as const;
export const UPDATE_LIFTED_COMPONENT = '@lifted-components/UPDATE_LIFTED_COMPONENT' as const;

export type MountComponentAction = {
  type: typeof MOUNT_LIFTED_COMPONENT;
  id: string;
  name: string | undefined;
};
function mountComponent(id: string, name: string): MountComponentAction {
  return {
    type: MOUNT_LIFTED_COMPONENT,
    id,
    name,
  };
}
export type UnmountComponentAction = {
  type: typeof UNMOUNT_LIFTED_COMPONENT;
  id: string;
};
function unmountComponent(id: string): UnmountComponentAction {
  return {
    type: UNMOUNT_LIFTED_COMPONENT,
    id,
  };
}

export type UpdateComponentAction<State> = {
  type: typeof UPDATE_LIFTED_COMPONENT;
  id: string;
  updates: Partial<State>;
};
function updateComponent<State>(id: string, updates: Partial<State>): UpdateComponentAction<State> {
  return {
    type: UPDATE_LIFTED_COMPONENT,
    id,
    updates,
  };
}

// eslint-disable-next-line default-param-last
export const reducer: Reducer<any> = (state = {}, action) => {
  switch (action.type) {
    case MOUNT_LIFTED_COMPONENT:
      if (state[action.id]) {
        throw new Error(
          `[LiftedComponents] Error mounting lifted component ${
            action.name ? action.name : ''
          }: An instance of this component is already mounted.`
        );
      }
      return state;
    case UNMOUNT_LIFTED_COMPONENT: {
      const { [action.id]: removedState, ...rest } = state;
      return rest;
    }
    case UPDATE_LIFTED_COMPONENT:
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] ? (state[action.id] as Record<string, unknown>) : {}),
          ...action.updates,
        },
      };
    default:
      return state;
  }
};

let id = 0;

export type ReduxLiftedComponentClass<Props, State> = ComponentClass<
  Props & { innerRef?: (any) => any }
> & {
  select: (any) => Partial<State>;
  update: (updates: Partial<State>) => UpdateComponentAction<State>;
  matchAction: (action: { type: string; id: string }) => boolean;
};

export function lifted<
  Props extends Record<string, unknown>,
  State extends Record<string, unknown>
>(WrappedComponent: ComponentClass<Props, State>): ReduxLiftedComponentClass<Props, State> {
  const LiftedComponent = liftedCore(WrappedComponent);
  const liftedId = `lifted_${id++}`;
  const select = (state) => state.lifted[liftedId] || LiftedComponent.initialState;
  const update = (updates) => updateComponent(liftedId, updates);
  update.actionType = 'lifted/update';
  const matchAction = (action) => action.type === UPDATE_LIFTED_COMPONENT && action.id === liftedId;
  const selector = (state) => ({
    _componentState: select(state),
  });
  const actions = {
    _onUpdate: update,
  };

  return class ReduxLifted extends React.Component<Props & { innerRef?: (any) => any }> {
    static displayName = `ReduxLifted${
      WrappedComponent.displayName || WrappedComponent.name || ''
    }`;

    static select = (state) => select(state) || {};

    static update = update;

    static matchAction = matchAction;

    render() {
      const { innerRef, ...other } = this.props;
      return (
        <Connect
          selector={selector}
          actions={actions}
          onMount={() => mountComponent(liftedId, ReduxLifted.displayName)}
          onUnmount={() => unmountComponent(liftedId)}
        >
          {({ _componentState, _onUpdate }) => (
            // @ts-expect-error
            <LiftedComponent
              {...other}
              ref={innerRef}
              state={_componentState}
              onLiftedChange={_onUpdate}
            />
          )}
        </Connect>
      );
    }
  };
}
