export type PriceObj = {
  amount: string;
  currencyIso: string;
};

export type BookingAddOn = {
  name: string;
  slug: string;
  pricePerUnit: string;
  pricePerUnitObj: PriceObj;
  providerPaymentPerUnit: string | null;
  providerPaymentPerUnitObj: PriceObj | null;
  icon?: string | null;
  totalPrice: string;
  active: boolean;
  frequency: string;
  unit: string;
  unitLabel: string;
  unitAsSentence: string;
  helpText: string;
  numUnits?: number | null;
  isAlternativeRate: boolean;
  isEditable: boolean;
  isFutureService: boolean;
  pet?: string;
  suggestedPetType: string;
  shouldShowOfferForFreeCheckbox: boolean;
  minPrice: string;
  useProviderPayment: boolean;
  priceLabel?: string | null;
  nameDesc?: string;
};

export const PriceObjDefaults: PriceObj = {
  amount: '',
  currencyIso: 'USD',
};

export const BookingAddOnDefaults = {
  name: '',
  slug: '',
  pricePerUnit: '',
  pricePerUnitObj: PriceObjDefaults,
  providerPaymentPerUnit: null,
  providerPaymentPerUnitObj: null,
  totalPrice: '',
  active: false,
  frequency: '',
  unit: '',
  unitLabel: '',
  unitAsSentence: '',
  helpText: '',
  numUnits: 0,
  isAlternativeRate: false,
  isEditable: false,
  isFutureService: false,
  pet: '',
  suggestedPetType: '',
  shouldShowOfferForFreeCheckbox: true,
  minPrice: '25',
  useProviderPayment: false,
  priceLabel: null,
};
