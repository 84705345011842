/**
 * What's going on here?
 * ---------------------
 * RxN does not support all of the available
 * strategies for emitting events, namely those that deal with the DOM
 * through jQuery.fn.trigger, so we have platform-specific index files
 * in this module that curry internal event emitter methods and inject
 * a list of strategy classes to evaluate when triggering the event
 *
 * New strategies must be added in all index files for the environments
 * they support
 */
import curry from 'lodash-es/curry';

import AndroidEmbeddedEventStrategy from './androidEmbeddedEventStrategy';
import DocumentEmbeddedEventStrategy from './documentEmbeddedEventStrategy';
import IOSEmbeddedEventStrategy from './iOSEmbeddedEventStrategy';
import {
  closeDialog as internalCloseDialog,
  openReferralModal as internalOpenReferralModal,
  triggerEmbeddedEvent as internalTriggerEmbeddedEvent,
} from './triggerEmbeddedEvent';

/**
 * Order matters; the first strategy to return true for #handesEvent
 * will be used to emit the event
 */
export const strategies = [
  AndroidEmbeddedEventStrategy,
  IOSEmbeddedEventStrategy,
  DocumentEmbeddedEventStrategy,
];
const exportedCurry = curry(internalTriggerEmbeddedEvent)(strategies);
export default exportedCurry;
export async function closeDialog() {
  await internalCloseDialog(strategies);
}
export async function openReferralModal() {
  await internalOpenReferralModal(strategies);
}
