import { I18N_FORMAT_MAP } from '@rover/kibble/styles';

import generateExternalFormatMap from './generateExternalFormatMap';
import generateFormatMap from './generateFormatMap';
import getLocalizedFormatInjected from './getLocalizedFormatInjected';

const EXTERNAL_FORMAT_MAP: Record<string, string> = generateExternalFormatMap(getLocalizedFormatInjected);
const FORMAT_KEYS: Record<string, string> = generateFormatMap(getLocalizedFormatInjected);

export {
  FORMAT_KEYS as default,
  getLocalizedFormatInjected as mf,
  EXTERNAL_FORMAT_MAP,
  I18N_FORMAT_MAP,
};
