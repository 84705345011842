import React, { HTMLInputTypeAttribute } from 'react';

import type { Props as LabelAndErrorFormGroupProps } from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';

import type { Props as AsInputProps } from '../../utils/AsInput';
import AsInput from '../../utils/AsInput';

type InputProps = Omit<AsInputProps, 'children'> & {
  canDismissValidationTip?: boolean;
  type?: HTMLInputTypeAttribute;
  step?: number | string;
  value?: string | readonly string[] | number | null;
  defaultValue?: string | readonly string[] | number;
  pattern?: string;
  maxLength?: number | string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  min?: string | number;
  max?: string | number;
  hasError?: boolean;
  autoComplete?: string;
  role?: React.AriaRole;
  title?: string;
  checked?: boolean;
  disabled?: boolean;
  'aria-required'?: boolean | 'true' | 'false';
  inputWidth?: string;
};

const Input = ({
  inputRef,
  onChange,
  sensitive,
  className,
  canDismissValidationTip,
  hasError,
  inputWidth,
  ...props
}: InputProps) => (
  <AsInput
    {...{
      inputRef,
      onChange,
      sensitive,
      className,
      canDismissValidationTip,
      hasError,
      width: inputWidth,
    }}
  >
    {(inputProps) => <input {...props} {...inputProps} />}
  </AsInput>
);

export type Props = LabelAndErrorFormGroupProps &
  InputProps & { validationType: 'popover' | 'inline' };
export default (props: Props): JSX.Element => (
  <LabelAndErrorFormGroup {...props}>
    {/*  we need to explicitly rename ref back to inputRef here so AsInput can pass it to the actual component  */}
    {({ ref, ...rest }) => (
      <Input
        {...rest}
        inputRef={ref}
        hasError={Boolean(props.validationType === 'inline' && props.validationError)}
      />
    )}
  </LabelAndErrorFormGroup>
);
export { Input as UnwrappedInput };
