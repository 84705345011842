import forEach from 'lodash-es/forEach';
import isFunction from 'lodash-es/isFunction';
import isPlainObject from 'lodash-es/isPlainObject';
import mapValues from 'lodash-es/mapValues';
import mergeWith from 'lodash-es/mergeWith';
import transform from 'lodash-es/transform';

import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import {
  FormError,
  FormErrors,
  FormValidator,
  FormValidators,
  FormValues,
} from '@rover/types/src/Form';

/**
 * This function creates an initial validationErrors
 * object with the appropriate shape. The result will
 * be in "valid" state meaning all leaf values are null.
 *
 * input:
 * {
 *   name: {
 *      first: Form.validators.required,
 *      last: Form.validators.required,
 *   },
 *   email: [Form.validators.required, Form.validators.minLength(3)],
 * }
 *
 * output:
 * {
 *   name: {
 *      first: null,
 *      last: null,
 *   },
 *   email: null,
 * }
 * @param {object} validators
 */
export const initializeValidation = <FV extends FormValues, FVD extends FormValidators<FV>>(
  initialData: FV,
  validators: FVD | ((data: FV) => FVD)
): FormErrors<FV, FVD> => {
  const resolvedValidators =
    typeof validators === 'function' ? validators(initialData) : validators;
  return mergeWith({}, resolvedValidators, resolvedValidators, (a, b) => {
    if (!isPlainObject(b)) {
      return null;
    }

    return undefined;
  });
};

/**
 * Validators can be optionally configured. If they
 * haven't been configured in any way, this function
 * will resolve them with default params.
 *
 * @param {function} validator
 */
export const resolveValidator = (validator: (...args: any[]) => any): FormValidator => {
  const testValue = validator();
  return isFunction(testValue) ? testValue : validator;
};

/**
 * This function takes two objects of the same shape:
 * the first contains user input, the other the validator
 * functions. It returns an object of the same shape with
 * error messages (or null) as values.
 *
 * input:
 * {
 *   name: {
 *      first: 'John',
 *      last: '',
 *   },
 *   email: 'jo',
 * },
 * {
 *   name: {
 *      first: Form.validators.required,
 *      last: Form.validators.required,
 *   },
 *   email: [Form.validators.required, Form.validators.minLength(3)],
 * }
 *
 * output:
 * {
 *   name: {
 *      first: null,
 *      last: 'This field is required.',
 *   },
 *   email: 'This field must be at least 3 characters.',
 * }
 * @param {object} data
 * @param {object} validators
 */
export const getErrors = <FV extends FormValues>(
  data: FV,
  validators: FormValidators<FV>
): FormErrors<FV, FormValidators<FV>> =>
  mapValues(validators, (value, key) => {
    // Recurse into nested objects
    const nextData = data[key];
    if (isPlainObject(value)) {
      return getErrors(nextData as FormValues, value);
    }

    // Assure validators are in an array
    const validatorList = Array.isArray(value) ? value : [value];
    // Run validators serially on data value,
    // terminating early if error is found
    let error: FormError = null;
    forEach(validatorList, (validator) => {
      if (!isFunction(validator)) {
        captureError(new Error(`Invalid validator for the input with name "${key}"`));
      } else {
        const resolvedValidator = resolveValidator(validator);
        if (typeof resolvedValidator === 'function') {
          error = resolvedValidator(data[key]);
        }
      }

      return error === null;
    });
    return error;
  });

/**
 * This function checks to see if there are any non-null
 * values (representing errors) in the object.
 *
 * @param {object} errors
 */
export const isValid = (errors: Record<string, any>): { valid: boolean } =>
  transform(
    errors,
    (result, value) => {
      if (value !== null && isPlainObject(value)) {
        result.valid = isValid(value).valid; // eslint-disable-line no-param-reassign
      } else {
        result.valid = value === null; // eslint-disable-line no-param-reassign
      }

      return result.valid;
    },
    {
      valid: true,
    }
  );

export const validate = <FV extends FormValues, FVD extends FormValidators<FV>>(
  data: FV,
  validators: FVD | ((initialData: FV) => FVD)
): { valid: boolean; errors: FormErrors<FV, FVD> } => {
  const resolvedValidators = typeof validators === 'function' ? validators(data) : validators;
  const errors = getErrors(data, resolvedValidators);
  return { ...isValid(errors), errors };
};
