import { DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';
import inject from '@rover/utilities/injector';

import getLocalizedFormatContextless from './getLocalizedFormatContextless';

const userLang = inject('Rover.context.language', DEFAULT_LANG);

const getLocalizedFormatInjected = (format: string, language: string = userLang): string =>
  getLocalizedFormatContextless(format, language);

export default getLocalizedFormatInjected;
