const invariant = (condition: any, message: string) => {
  if (process.env.NODE_ENV === 'development') {
    // This nested if will allow all of this
    // code to be removed in prod through minification
    if (!condition) {
      throw new Error(message);
    }
  }
};

export default invariant;
