import dataLayer from '@rover/globals/dataLayer';
import type { DataLayerEvent } from '@rover/types';

function pushEvent(event: DataLayerEvent): Promise<void> {
  return new Promise((resolve) => {
    // Use setTimeout to ensure the main CPU thread is freed up between invocations of GTM
    setTimeout(() =>
      dataLayer.push({
        ...event,
        eventCallback: resolve,
      })
    );
  });
}

function fireDataLayerEvent(event: DataLayerEvent, timeout = 500): Promise<void> {
  return Promise.race([
    pushEvent(event),
    // GTM may be blocked or the script may fail to process the queue.
    // In those cases the above event promise will never resolve, so provide
    // a failsafe
    new Promise<void>((resolve) => {
      setTimeout(resolve, timeout);
    }),
  ]);
}

export default fireDataLayerEvent;
