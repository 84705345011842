import { AxiosResponse } from 'axios';

import { CookiePurpose, createCookie } from '@rover/rsdk/src/modules/Network/cookies';
import { ROVER_GTM_COOKIES_HEADER } from '@rover/shared/js/constants/headers';

export type GtmCookie = {
  purpose: CookiePurpose;
  key: string;
  value: string;
  max_age?: number; // in seconds
  days?: number; // in days
};

const createGtmCookiesFromResponse = (response: AxiosResponse): void => {
  const headers = response.headers || {};
  const header = headers[ROVER_GTM_COOKIES_HEADER] || '[]';
  const cookies: GtmCookie[] = JSON.parse(header);
  cookies.forEach((cookie) => {
    createCookie(cookie.purpose, cookie.key, cookie.value, cookie.days);
  });
};

export default createGtmCookiesFromResponse;
