export enum PopoverPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}
export enum PopoverEvent {
  HOVER = 'hover',
  CLICK = 'click',
  NONE = 'none',
}
