import uuid from 'uuid';

import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import Mustache from '@rover/globals/Mustache';
/**
 * Bootstrap Modal wrapper for use with Backbone.
 *
 * Takes care of instantiation, adds several options,
 * and removes the element from the DOM when closed.
 *
 * @author Charles Davison <charlie@powmedia.co.uk>
 * Adapted by Ben Brooks <ben@rover.com> for Rover
 *
 * Events:
 * shown: Fired when the modal has finished animating in
 * hidden: Fired when the modal has finished animating out
 * cancel: The user dismissed the modal
 * ok: The user clicked OK
 */
import _ from '@rover/globals/underscore';
import { removeFocusTrap, trapFocusWithinElement } from '@rover/utilities/modals';
import i18n from '@rover/utilities/translation';
import { t } from '@lingui/macro';

export default Backbone.View.extend({
  events: {
    'click .js-modal-cancel': 'onCancelClick',
    'click .js-modal-primary-action': 'onOkClick',
    'click .js-modal-extra-action': 'onExtraClick',
  },

  /**
   * Creates an instance of a Bootstrap Modal
   *
   * @see http://twitter.github.com/bootstrap/javascript.html#modals
   *
   * @param {Object} options
   * @param {String|Obj} [options.content]              Modal content. Can be a string or a jquery element
   * @param {String} [options.title]                    Modal title.
   * @param {String} [options.okText]                   Text for the OK button.
   * @param {String} [options.OkType]                   The type of button 'default', 'primary'
   *                                                    , 'secondary', 'tertiary', 'danger'
   * @param {Boolean} [options.okFocus]                 Whether the OK button should be in focus
   *                                                    when the modal launches.
   * @param {String} [options.okCloses]                 Whether the OK button should close the modal.
   * @param {String} [options.cancelText]               Text for the cancel button. Default: 'Cancel'.
   *                                                    If passed a falsey value,
   *                                                    the button will be removed
   * @param {Boolean} [options.showFooter]              Whether or not to show the button toolbar footer
   * @param {Boolean} [options.disableBackdropClick]    Whether to allow closing the model when clicking outside
   * @param {Boolean} [options.disableCancel]           Whether or not to remove all closing UI
   * @param {Boolean} [options.animate]                 Whether to animate in/out
   * @param {Object} [options.template]                 Mustache template
   * @param {Str} [options.classes]                     Additional classes on the modal
   * @param {Object} [options.attrs]                    String of additional attributes
   * @param {Boolean} [options.padding]                 Whether to pad the main content well
   * @param {Boolean} [options.applyScrollFix]          Apply scroll fixes for tall modals, best to
   *                                                    leave on unless you know your modal won't scroll
   */
  initialize(options) {
    this.options = _.extend(
      {
        content: null,
        title: null,
        okText: i18n._(t`Okay`),
        okType: 'primary',
        okIcon: null,
        okFocus: false,
        okCloses: false,
        okEnabled: true,
        cancelText: i18n._('Cancel'),
        showExtraButton: false,
        extraText: '',
        extraIcon: null,
        extraType: 'danger',
        extraCloses: false,
        extraPullLeft: true,
        centerVertically: false,
        showFooter: false,
        disableBackdropClick: false,
        disableCancel: false,
        disableESCKey: false,
        animate: true,
        template: Mustache.template('new_design/common/modal'),
        classes: '',
        dialogClasses: '',
        attrs: null,
        padding: true,
        applyScrollFix: true,
      },
      options
    );

    // Set bootstrap modal options
    this.bsModalOptions = _.extend(
      {
        keyboard: !this.options.disableCancel && !this.options.disableESCKey,
        backdrop: this.options.disableCancel || this.options.disableBackdropClick ? 'static' : true,
      },
      options.bsModalOptions
    );

    // Correct numerous scrolling issues with bootstrap modal
    if (this.options.applyScrollFix) {
      this.initScrollWithBody();
    }

    this.render();
  },

  render() {
    // Create the modal container
    this.options.uuid = uuid.v4();
    this.$el.html(this.options.template.render(this.options));

    // Cache interaction elements for later use
    this.$modal = this.$('.js-rover-modal');
    this.$okButton = this.$('.js-modal-primary-action');
    this.$modalContent = this.$('.js-modal-inner-body');
    this.$modalTitle = this.$('.js-modal-title');

    // If content is a reference to an element, set it as the content
    if (this.options.content instanceof $) {
      this.setContent(this.options.content);
    }

    // Set listeners
    this.$modal.on('shown.bs.modal', _.bind(this.onModalShown, this));
    this.$modal.on('show.bs.modal', _.bind(this.onModalShow, this));
    this.$modal.on('hidden.bs.modal', _.bind(this.onModalHidden, this));
    this.on('cancel', this.onCancel, this);
    this.on('ok', this.onOk, this);
    this.on('extra', this.onExtra, this);

    if (!this.options.okEnabled) {
      this.disableOk();
    }

    // Add to DOM
    this.$el.appendTo('body');
  },

  open() {
    // Remember which element had focus when the modal was summoned
    this.previouslyActiveElement = document.activeElement;

    this.$modal.modal(this.bsModalOptions);
    return this;
  },

  setContent(content) {
    this.$modalContent.html(content);
  },

  getContent() {
    return this.$modalContent;
  },

  appendContent(content) {
    this.$modalContent.append(content);
  },

  setTitle(text) {
    this.$modalTitle.html(text);
  },

  close() {
    this.$modal.modal('hide');

    return this;
  },

  onCancelClick(event) {
    event.preventDefault();
    this.trigger('cancel');
  },

  onOkClick(event) {
    event.preventDefault();
    this.trigger('ok');
  },

  onExtraClick(event) {
    event.preventDefault();
    this.trigger('extra');
  },

  onModalHidden() {
    this.trigger('hidden');
    // Return focus to the element that had it before the dialog was shown (likely the trigger)
    if (this.previouslyActiveElement) this.previouslyActiveElement.focus();
    if (this.tabListener) removeFocusTrap(this.tabListener);
  },

  onModalShown() {
    if (this.options.okFocus) {
      this.$okButton.focus();
    }
    this.tabListener = trapFocusWithinElement(this.$modal);
    this.trigger('shown');
  },

  onModalShow() {
    this.trigger('show');
  },

  onCancel() {
    this.close();
  },

  onOk() {
    if (this.options.okCloses) {
      this.close();
    }
  },

  onExtra() {
    if (this.options.extraCloses) {
      this.close();
    }
  },

  enableOk() {
    this.$okButton.prop('disabled', false);
  },

  disableOk() {
    this.$okButton.prop('disabled', true);
  },

  // This modifies bootstrap's default modal scrolling behavior
  // so that modals scroll with the body instead of in their own div.
  // This solves multiple scrolling issues:
  //  * the page scrolling behind the modal in iOS while the modal remains still
  //  * modal form fields jumping around in iOS
  //  * the modal backdrop not covering the whole page when iOS/Android devices scroll
  //  * jquery-ui's datepicker not scrolling with date inputs
  initScrollWithBody() {
    // Force modal to scroll with body
    this.on('show', () => {
      $('body').css('overflow', 'visible');
      this.$modal.css({
        position: 'absolute',
        overflow: 'visible',
        top: $(document).scrollTop(),
      });
    });

    // Modal overlay improvements to go along with body scroll
    this.on('shown', () => {
      // Make backdrop cover entire screen on iOS/Android scroll
      $('.modal-backdrop').css({
        bottom: '-100px',
        top: '-100px',
      });

      // Allow for backdrop to close modal unless disableCancel or disableBackdropClick are set
      $('body').on('click.rover-modal-overlay-close', '.modal-backdrop', () => {
        if (!this.options.disableCancel && !this.options.disableBackdropClick) {
          this.close();
        }
      });
    });

    this.on('hidden', () => {
      // Stop listening for overlay clicks
      $('body').off('click.rover-modal-overlay-close');
      $('body').css('overflow', 'visible');
    });
  },
});
