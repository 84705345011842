import { CurrentUserViewSchema } from '@rover/rsdk/src/apiClient/latest';

import userFactory from './userFactory';

export default function fetchCurrentUserResponseFactory(
  opts?: Partial<CurrentUserViewSchema>
): CurrentUserViewSchema {
  return {
    authenticated: false,
    currentUser: userFactory(),
    realUser: userFactory(),
    session: {
      utmParameters: {},
      firstHttpReferrer: '',
    },
    ...opts,
  };
}
