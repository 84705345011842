import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import { getCookie } from '../cookies';
import type { AxiosUtilsInterface } from '../types';
import { isiOS, isMobileBrowser, isMobileEmbedded } from '../userAgent';

import axiosErrorHandler from './axiosErrorHandler';
import createGtmCookiesFromResponse from './createGtmCookiesFromResponse';
import paramsSerializer from './paramsSerializer';
import sanitizeCsrf from './sanitizeCsrf';

export const DEFAULT_CSRF_COOKIE_NAME = 'csrftoken';
export const CSRF_META_TAG_NAME = 'csrf-cookie-name';

export const getCsrfCookieName = (): string => {
  const meta = document.querySelector(`meta[name=${CSRF_META_TAG_NAME}]`);
  const metaCookieName = meta && meta.getAttribute('content');
  return metaCookieName || DEFAULT_CSRF_COOKIE_NAME;
};

export const getSourceDescription = (): string => {
  const { userAgent } = navigator;
  if (!userAgent || !userAgent.length || !isMobileBrowser(userAgent)) return 'web'; // If the user agent string doesn't exist, assume this is a web request

  if (!isMobileEmbedded()) return 'mobile_web';
  return isiOS(userAgent) ? 'ios' : 'android';
};

export const getSourceHeader = (): { 'X-Rover-Source': string } => {
  const source = getSourceDescription();
  return {
    'X-Rover-Source': source,
  };
};

export const getCsrfToken = (): string | null => {
  const rawCookie = getCookie(getCsrfCookieName());
  return sanitizeCsrf(rawCookie);
};

export const isRoverUrl = (url: string): boolean => {
  if (typeof window === 'undefined') return false;
  return (
    url.includes(window.location.hostname) ||
    url.startsWith('/') ||
    url.startsWith('./') ||
    url.startsWith('../')
  );
};

export const addRoverHeaders = (config: any) => {
  const roverConfig = { ...config };
  const token = getCsrfToken();
  const authorization = getCookie('Authorization');

  roverConfig.headers = {
    ...config.headers,
    ...getSourceHeader(),
    ...(token && {
      'X-CSRFToken': token,
    }),
    ...(authorization && {
      Authorization: authorization,
    }),
  };

  return roverConfig;
};

export const createRequestInterceptor = () => (defaultConfig: AxiosRequestConfig) => {
  const isRover = isRoverUrl(defaultConfig.url || '');
  if (!isRover) return defaultConfig; // non-rover urls get NOTHING, to avoid CORS issues

  const configWithHeaders = addRoverHeaders(defaultConfig);
  return configWithHeaders;
};

export const createResponseInterceptor = () => (response: AxiosResponse) => {
  createGtmCookiesFromResponse(response);
  return response;
};

export const getAxiosInstance = (): AxiosInstance => {
  const ai = axios.create({
    paramsSerializer,
  });
  ai.interceptors.request.use(createRequestInterceptor(), axiosErrorHandler);
  ai.interceptors.response.use(createResponseInterceptor());
  return ai;
};

const axiosUtils: AxiosUtilsInterface = {
  getAxiosInstance,
};

export default axiosUtils;
