import { Component } from 'react';

export type Props = {
  shouldScroll: boolean;
  errorClass: string;
};

export default class ScrollToError extends Component<Props> {
  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      this.props.shouldScroll !== nextProps.shouldScroll ||
      this.props.errorClass !== nextProps.errorClass
    );
  }

  componentDidUpdate(): void {
    if (process.env.JS_ENV_CLIENT) {
      const { shouldScroll, errorClass } = this.props;

      const errorElement = document.getElementsByClassName(errorClass)[0];
      if (shouldScroll && errorElement) {
        errorElement.scrollIntoView({ block: 'center' });
      }
    }
  }

  render(): null {
    return null;
  }
}
