/* eslint-disable rover/no-platform-specific-globals-or-imports */
import $ from '@rover/globals/jquery';

function getFocusableElementsWithinElement(element) {
  const bareElement = element instanceof $ ? element[0] : element;
  return [
    ...bareElement.querySelectorAll(
      'a[href], button, input:not([type=hidden]), textarea, select, details, [tabindex]:not([tabindex="-1"])'
    ),
  ] // filter out disabled...
    .filter((node) => node.matches(':not(:disabled)')) // ...and display: none elements
    .filter((node) => !!(node.offsetWidth || node.offsetHeight || node.getClientRects().length));
}

function trapFocusWithinElement(element) {
  // Use for modal dialogs to "trap" tab focus while modal is active: focus will cycle only among elements within modal.
  // Call untrapFocus() below with the return value of this function on modal close.
  const focusableContent = getFocusableElementsWithinElement(element);
  const firstFocusableElement = focusableContent[0];
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  const tabFocusListener = (e) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    if (!isTabPressed) return;

    if (e.shiftKey && document.activeElement === firstFocusableElement) {
      // if shift-tab from first focusable element inside dialog: move to last one and prevent default
      lastFocusableElement.focus();
      e.preventDefault();
    } else if (!e.shiftKey && document.activeElement === lastFocusableElement) {
      // tab from last focusable element: move to first one and prevent default
      firstFocusableElement.focus();
      e.preventDefault();
    }
  };

  // Keydown listener for tab key to trap keyboard focus in modal
  document.addEventListener('keydown', tabFocusListener);
  return tabFocusListener;
}

function removeFocusTrap(listenerReference) {
  document.removeEventListener('keydown', listenerReference);
}

export { trapFocusWithinElement, removeFocusTrap };
