import frontendDefaultApiVersion from './apiDefault';

export * from './apiVersion';

const getRootUrl = (): string => {
  let rootUrl = '';

  if (process.env.WEBAPP_ROOT_URL) {
    rootUrl = process.env.WEBAPP_ROOT_URL.replace(/\/$/, ''); // strip the trailing slash if there is one
  }

  return rootUrl;
};

export const fullUrl = (path: string): string => {
  const rootUrl = getRootUrl();
  return rootUrl ? `${rootUrl}${path}` : path;
};

export const systemsUrl = (suffix = ''): string => `/systems/${suffix}`;

export const fullSystemsUrl = (suffix: string): string => fullUrl(systemsUrl(suffix));

export const apiUrl = (
  suffix = '',
  version: string = frontendDefaultApiVersion.toString()
): string => {
  return `/api/${version}/${suffix}`;
};

export const fullApiUrl = (suffix: string, version?: string): string =>
  fullUrl(apiUrl(suffix, version));

export { frontendDefaultApiVersion };

const exportedApiUrl = {
  apiUrl,
  fullApiUrl,
};

export default exportedApiUrl;
