import styled, { css } from 'styled-components';

import { Color, ZIndex } from '@rover/kibble/styles';

export const TRANSITION_DURATION = 150;
type StyledPopoverProps = {
  transitionState: string;
  backgroundColor?: Color;
  borderColor?: Color;
  showArrow?: boolean;
  arrowStyle: Record<string, string | number>;
  isOutOfBoundaries?: boolean;
  maxWidth?: string;
};
const StyledPopover = styled.div<StyledPopoverProps>`
  transition: opacity ${TRANSITION_DURATION}ms linear;
  opacity: ${(props) => (props.transitionState === 'entered' ? 1 : 0)};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor.toString() : '')};
  text-align: left;
  border: 1px solid ${(props) => (props.borderColor ? props.borderColor.toString() : '')};
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 8px;
  z-index: ${ZIndex.POPOVER.toString()};
  max-width: ${({ maxWidth }): string => maxWidth || '450px'};
  visibility: ${({ isOutOfBoundaries }) => (isOutOfBoundaries ? 'hidden' : 'inherit')};

  ${({ showArrow, arrowStyle, borderColor, backgroundColor }) =>
    !showArrow
      ? ''
      : css`
          &:before,
          &:after {
            border: 1px solid ${borderColor ? borderColor.toString() : ''};
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-width: 8px;
          }

          &:before {
            border-color: rgba(204, 204, 204, 0);
            border-width: 9px;
          }

          &[data-placement='bottom'] {
            margin-top: 8px;

            &:before,
            &:after {
              bottom: 100%;
              left: ${arrowStyle.left}px;
            }

            &:after {
              border-bottom-color: ${backgroundColor ? backgroundColor.toString() : ''};
              margin-left: -8px;
            }

            &:before {
              border-bottom-color: ${borderColor ? borderColor.toString() : ''};
              margin-left: -9px;
            }
          }

          &[data-placement='top'] {
            margin-bottom: 8px;

            &:before,
            &:after {
              top: 100%;
              left: ${arrowStyle.left}px;
            }

            &:after {
              border-top-color: ${backgroundColor ? backgroundColor.toString() : ''};
              margin-left: -8px;
            }

            &:before {
              border-top-color: ${borderColor ? borderColor.toString() : ''};
              margin-left: -9px;
            }
          }

          &[data-placement='right'] {
            margin-left: 8px;

            &:before,
            &:after {
              right: 100%;
              top: ${arrowStyle.top}px;
            }

            &:after {
              border-right-color: ${backgroundColor ? backgroundColor.toString() : ''};
              margin-bottom: -8px;
            }

            &:before {
              border-right-color: ${borderColor ? borderColor.toString() : ''};
              margin-bottom: -9px;
            }
          }

          &[data-placement='left'] {
            margin-right: 8px;

            &:before,
            &:after {
              left: 100%;
              top: ${arrowStyle.top}px;
            }

            &:after {
              border-left-color: ${backgroundColor ? backgroundColor.toString() : ''};
              margin-top: -8px;
            }

            &:before {
              border-left-color: ${borderColor ? borderColor.toString() : ''};
              margin-top: -9px;
            }
          }
        `};
`;
export default StyledPopover;
