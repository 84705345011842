import React, { Component } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import classNames from 'classnames';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import { CLASS_NAMES } from '@rover/react-lib/src/constants';

import Popover, { PopoverPlacement } from '../../callouts/Popover';

export type Props = {
  placement: PopoverPlacement;
  children: React.ReactNode;
  message: MessageDescriptor | string | null | undefined;
  className?: string;
  didSubmit?: boolean;
  canDismiss?: boolean;
} & Partial<Omit<React.ComponentProps<typeof Popover>, 'event' | 'content'>>;

type State = {
  lastMessage: MessageDescriptor | string;
  isDismissed: boolean;
};

export const ErrorWrapper = styled.div`
  color: ${DSTokenMap.TEXT_COLOR_ERROR.toString()};
`;

const getClassNames = (message, className) =>
  classNames(className, {
    [CLASS_NAMES.FORM_ERROR_CLASSNAME]: message,
  });

export default class FormValidationError extends Component<Props, State> {
  popover: {
    current: null | Popover;
  };

  static defaultProps = {
    placement: Popover.Placement.TOP,
    message: '',
  };

  constructor(props: Props) {
    super(props);
    this.popover = React.createRef();

    if (props.canDismiss) {
      FormValidationError.canDismiss = true;
    }
  }

  state = {
    lastMessage: '',
    isDismissed: false,
  };

  componentDidMount(): void {
    if (process.env.JS_ENV_CLIENT) {
      document.addEventListener('click', this.handleClick, false);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Props): void {
    // Make sure all FormValdiationErrors get that there was just a submit
    if (newProps.didSubmit) {
      FormValidationError.didSubmit = true;
    }

    const isNewSubmission = FormValidationError.didSubmit;
    const isNewError = this.props.message !== newProps.message;

    // All new submissions and new errors should reset the dismissal
    if (isNewSubmission || isNewError) {
      this.setState(() => ({
        lastMessage: newProps.message || '',
        isDismissed: false,
      }));
    }
  }

  componentWillUnmount(): void {
    if (process.env.JS_ENV_CLIENT) {
      document.removeEventListener('click', this.handleClick, false);
    }
  }

  // If the last action was a submit, validation messages will reappear.
  static didSubmit = true;

  // Determines if the validaiton errors can be dismissed.
  // canDismiss will be passed in as a prop if it validation messages can be dismissed.
  static canDismiss = false;

  handleClick = (): void => {
    // Dismiss validation messages when clicked.
    if (FormValidationError.canDismiss) {
      this.setState(() => ({
        isDismissed: true,
      }));
      FormValidationError.didSubmit = false;
    }
  };

  renderContent(): JSX.Element {
    const { message } = this.props;
    const { lastMessage } = this.state;
    return (
      <ErrorWrapper role="alert">
        <Trans id={message || lastMessage} />
      </ErrorWrapper>
    );
  }

  render(): JSX.Element {
    const { placement, children, message, className, ...other } = this.props;
    const { isDismissed } = this.state;
    return (
      <Popover
        ref={this.popover}
        // I don't think this is actually doing anything, but I don't want to risk breaking everything
        // during a Typescript conversion ticket
        // @ts-expect-error
        onClick={this.handleClick}
        event={Popover.Event.NONE}
        placement={placement}
        content={this.renderContent()}
        open={!!message && !isDismissed}
        display="block"
        className={getClassNames(message, className)}
        {...other}
      >
        {children}
      </Popover>
    );
  }
}
