import moment from 'moment';

import type { Time } from '@rover/types';

export const isOverlappingTime = (
  idx: number,
  times: Time[],
  timeSelectionDuration: number
): boolean => {
  if (times.length === 1 || idx >= times.length) return false;
  const currentTime = moment(times[idx].value, 'hh:mm A');
  // We want to check if the given time overlaps with any of the previous times
  return times.slice(undefined, idx).some(({ value: otherTime }) => {
    const otherTimeMoment = moment(otherTime, 'hh:mm A');
    const overlapStart = otherTimeMoment.clone().subtract(timeSelectionDuration, 'minutes');
    const overlapEnd = otherTimeMoment.clone().add(timeSelectionDuration, 'minutes');
    return currentTime.isBetween(overlapStart, overlapEnd);
  });
};
