import React from 'react';
import styled from 'styled-components';
import { compose, maxWidth as maxWidthSystem, space, variant } from 'styled-system'; // Imports maxWidth prop without entire layout system

import { DSTokenMap, fontWeight, textAlign, textColor } from '@rover/kibble/styles';

import Box from '../Box';
import { LayoutSystemProps } from '../styledSystemPropTypes';

import { ParagraphCommonProps } from './Paragraph.common';

export type Props = ParagraphCommonProps & {
  maxWidth?: LayoutSystemProps['maxWidth'];
  display?: LayoutSystemProps['display'];
  id?: string;
  as?: 'p' | 'span' | 'div';
} & React.HTMLAttributes<HTMLParagraphElement>;

const StyledParagraph = styled(Box)<Props>(
  // Resets values in case of global styles
  {
    padding: '0',
    margin: '0',
    border: '0 none',
  },
  variant({
    prop: 'size',
    variants: {
      // TODO: move these styles to Style Dictionary to format into theme.ts\
      50: {
        fontSize: DSTokenMap.PARAGRAPH_50_FONT_SIZE,
        lineHeight: DSTokenMap.PARAGRAPH_50_LINE_HEIGHT,
        marginBottom: DSTokenMap.PARAGRAPH_50_MARGIN_BOTTOM,
      },
      100: {
        fontSize: DSTokenMap.PARAGRAPH_100_FONT_SIZE,
        lineHeight: DSTokenMap.PARAGRAPH_100_LINE_HEIGHT,
        marginBottom: DSTokenMap.PARAGRAPH_100_MARGIN_BOTTOM,
      },
      200: {
        fontSize: DSTokenMap.PARAGRAPH_200_FONT_SIZE,
        lineHeight: DSTokenMap.PARAGRAPH_200_LINE_HEIGHT,
        marginBottom: DSTokenMap.PARAGRAPH_200_MARGIN_BOTTOM,
      },
      300: {
        fontSize: DSTokenMap.PARAGRAPH_300_FONT_SIZE,
        lineHeight: DSTokenMap.PARAGRAPH_300_LINE_HEIGHT,
        marginBottom: DSTokenMap.PARAGRAPH_300_MARGIN_BOTTOM,
      },
    },
  }),
  compose(textAlign, textColor, fontWeight, space, maxWidthSystem) // Apply last to avoid being overwritten by resets and variant styles
);

function Paragraph({
  maxWidth = '640px', // reasoning behind this default is at http://www.rover.com/storybook/?path=/docs/kibble-components-paragraph--default-story#length
  size = '200',
  as = 'p',
  a11yLabel,
  a11yRole,
  ...other
}: Props): JSX.Element {
  return (
    <StyledParagraph
      role={a11yRole}
      aria-label={a11yLabel}
      {...other}
      as={as}
      maxWidth={maxWidth}
      size={size}
    />
  );
}

export default Paragraph;
