/* Allowing every URL to be sent as a tag in StatsD would give us an unbounded number of URLs. Instead we match URLs with regexes, then give them a static tag. */

import { STATSD_URL_TAG_MAP_ARRAY } from '../../../public-configs';
import { captureError } from '../../ErrorReporting';

const exportedStatsdTagForUrl = (url: string, fallback = 'unknown'): string => {
  const urlTagMap = STATSD_URL_TAG_MAP_ARRAY.find(({ regex }: { regex: RegExp }) =>
    regex.test(url)
  );
  if (!urlTagMap) {
    captureError(
      new Error(
        `Could not determine StatsD tag for url "${url}" - falling back to "${fallback}" instead. Make sure to update 'getStatsdTagForUrl.ts' soon`
      )
    );
    return fallback;
  }
  return urlTagMap.tag;
};
export default exportedStatsdTagForUrl;
