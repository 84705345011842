import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

import type { CookieInterface } from '../types';

import getCookieByName from './getCookieByName';
import getCookieDomain from './getCookieDomain';
import getExpires from './getExpires';
import { CookieDomain, CookiePurpose } from './types';

const PATH = '/';

const getDomain = (): CookieDomain => getCookieDomain(window.location.hostname);

export const getCookie = (name: string): string | undefined =>
  getCookieByName(name, document.cookie);

export const createCookie = (
  purpose: CookiePurpose,
  name: string,
  value: string,
  days?: number
): void => {
  if (purpose === CookiePurpose.StrictlyNecessary) {
    let cookie = `${name}=${value};domain=${getDomain()};path=${PATH}`;
    if (days) {
      const expires = getExpires(days).toUTCString();
      cookie += `;expires=${expires}`;
    }
    document.cookie = cookie;
  } else {
    fireDataLayerEvent({
      event: `cookie-consent-set-${purpose}-cookie`,
      webappCookie: {
        purpose,
        key: name,
        value,
        domain: getDomain(),
        max_age: days ? days * 86400 : undefined,
        path: PATH,
      },
    });
  }
};

export const removeCookie = (name: string): void => {
  if (!getCookie(name)) return;
  document.cookie = `${name}=;path=${PATH};domain=${getDomain()};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const hasCookie = (name: string): boolean => !!getCookie(name);

const cookie: CookieInterface = {
  getCookie,
  createCookie,
  removeCookie,
  hasCookie,
};

export default cookie;
