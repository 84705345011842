/* eslint-disable rover/no-platform-specific-globals-or-imports */
import $ from '@rover/globals/jquery';

import EmbeddedEventStrategy from './embeddedEventStrategy';

export default class DocumentEmbeddedEventStrategy extends EmbeddedEventStrategy {
  static handlesEvent(
    eventName: string,
    {
      isAndroid,
      isiOS,
    }: {
      isAndroid: boolean;
      isiOS: boolean;
    }
  ): boolean {
    return !!document && (isAndroid || isiOS);
  }

  triggerEvent(eventName: string): void {
    try {
      const eventData: any = {
        type: eventName,
      };
      $(document).trigger(eventData);
    } catch (e) {
      this.errorHandler(e as Error);
    }
  }
}
