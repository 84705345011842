import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import type { CommonProps } from './FormGroup.types';

// The Wrapped component is being used in several places to style components
// switching to a kibble component would require a large refactor
// eslint-disable-next-line rover/prefer-kibble-components
export const Wrapper = styled.div`
  position: relative;
`;

export type Props = CommonProps & {
  className?: string;
};

export const FormGroup = ({ className, children, ...other }: Props): ReactElement => (
  <Wrapper className={classNames(className, 'form-group')} {...other}>
    {children}
  </Wrapper>
);

export default FormGroup;
