/* eslint-env jest */
import { Action, combineReducers, createStore, Dispatch, Reducer } from 'redux';

import type {
  ActionTypes,
  DuckOptions,
  EffectTypes,
  GlobalReduxState,
  SelectorTypes,
} from '@rover/react-lib/src/lib/redux-duck';

const ERROR_MESSAGE_FIXTURE = 'Error Message';

type RequiredState = {
  loading: boolean;
  errorMessage: string;
};

const generateActions = <DuckState extends RequiredState>(action: string): any => ({
  [`${action}Pending`]: () => (state: DuckState) => ({
    ...state,
    loading: true,
    errorMessage: '',
  }),
  [`${action}Success`]: (response) => (state: DuckState) => ({
    ...state,
    [`${action}Response`]: response,
    loading: false,
    errorMessage: '',
  }),
  [`${action}Failure`]: (errorMessage) => (state: DuckState) => ({
    ...state,
    errorMessage,
    loading: false,
  }),
});

export const generateActionTests = <
  DuckState extends RequiredState,
  DuckActions extends ActionTypes,
  DuckEffects extends EffectTypes,
  DuckSelectors extends SelectorTypes
>(
  action: string,
  responseFixture: Record<string, any>,
  actions: DuckActions,
  options: DuckOptions<DuckState, DuckActions, DuckEffects, DuckSelectors>,
  reducer: Reducer<DuckState>
): any => {
  describe(action, () => {
    let dispatch: Dispatch<Action<unknown>>;
    let getState: () => { test: DuckState } & GlobalReduxState;

    beforeEach(() => {
      ({ dispatch, getState } = createStore(
        combineReducers({
          test: reducer,
        })
      ));
    });

    it(`sets ${action}Pending state`, () => {
      expect(getState().test.loading).toBe(options.initialState.loading);
      dispatch(actions[`${action}Pending`](null));
      expect(getState().test.loading).toBe(true);
    });

    it(`sets ${action}Failure state`, () => {
      expect(getState().test.errorMessage).toBe(options.initialState.errorMessage);
      dispatch(actions[`${action}Failure`](ERROR_MESSAGE_FIXTURE));
      expect(getState().test.errorMessage).toBe(ERROR_MESSAGE_FIXTURE);
    });

    it(`sets ${action}Success state`, () => {
      expect(getState().test[`${action}Response`]).toEqual(
        options.initialState[`${action}Response`]
      );
      dispatch(actions[`${action}Success`](responseFixture));
      expect(getState().test[`${action}Response`]).toEqual(responseFixture);
    });
  });
};

export default generateActions;
