import flat from 'flat';
import isString from 'lodash-es/isString';
import values from 'lodash-es/values';

type Error = {
  [key: string]: Error | null | string;
};

export default function hasFormError(error: Error): boolean {
  // NOTE(JC): form error can be a nested object:
  // {
  //   address: {
  //     address_1: 'some error',
  //     address_2: null,
  //   },
  //   location: null
  // }
  // Fields with error will have an error message under that key. And correct
  // fields will have null value.
  // To figure out whether this nested error object actually has error, there are 3
  // steps:
  // 1) flatten the error object so it is no longer nested:
  // {
  //   address.address_1: 'some error',
  //   address.address_2: null,
  //   location: null,
  // }
  // 2) transform the error object to an array:
  // ['some error', null, null];
  // 3) filter the array to only keep the strings
  // if we have 1 or more items in the array, we have an error.
  return values(flat(error)).filter(isString).length > 0;
}
