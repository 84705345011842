import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { DSTokenMap, FocusOutline } from '@rover/kibble/styles';
import { SENSITIVE_DATA_CLASS } from '@rover/react-lib/src/constants/sensitiveData.constants';

export const Wrapper = styled(({ children, ...props }) => React.cloneElement(children, props))`
  height: 40px;
  width: ${(props) => props.width ?? '100%'};
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY.toString()};
  border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
    ${(props) =>
      props.haserror === 'true'
        ? `${DSTokenMap.BORDER_COLOR_ERROR.toString()} !important`
        : DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
  display: block;
  padding: ${DSTokenMap.SPACE_2X};
  transition: border-color ease-in-out 0.15s;

  &:focus {
    ${(props) =>
      props.haserror === 'true'
        ? FocusOutline({ outlineOffset: 0 })
        : FocusOutline({ outlineOffset: 2 })};
  }
  &:focus-within {
    ${(props) =>
      props.haserror === 'true'
        ? FocusOutline({ outlineOffset: 0 })
        : FocusOutline({ outlineOffset: 2 })};
  }

  &:disabled {
    background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_DISABLED.toString()};
    border: 2px solid ${DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()};
    cursor: not-allowed;
    opacity: 1;
  }
`;

type InputRef = any;
// type InputRef = React.RefObject<HTMLInputElement>;

type InputProps = {
  className: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: InputRef;
};

export type Props = {
  children: (InputProps) => React.ReactNode;
  inputRef?: InputRef;
  onChange?: (value: string) => void;
  sensitive?: boolean;
  hasError?: boolean;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
  width?: string;
};

const getClassNames = (sensitive, className) =>
  classNames(className, {
    [SENSITIVE_DATA_CLASS]: sensitive,
  });

const AsInput = ({
  children,
  className = '',
  inputRef,
  onChange,
  sensitive = false,
  hasError,
  width,
}: Props): JSX.Element => {
  const inputProps: InputProps = {
    className: getClassNames(sensitive, className),
    onChange: (event) => onChange && onChange(event.target.value),
    ref: inputRef,
  };

  return (
    <Wrapper className={getClassNames(sensitive, className)} haserror={`${hasError}`} width={width}>
      {children(inputProps)}
    </Wrapper>
  );
};

export default AsInput;
