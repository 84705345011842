/* eslint-disable rover/no-platform-specific-globals-or-imports */
import EmbeddedEventStrategy from './embeddedEventStrategy';

export default class IOSEmbeddedEventStrategy extends EmbeddedEventStrategy {
  static handlesEvent(
    eventName: string,
    {
      isiOS,
    }: {
      isiOS: boolean;
    }
  ): boolean {
    return isiOS;
  }

  triggerEvent(eventName: string) {
    const rover = IOSEmbeddedEventStrategy.getRoverInstance();

    try {
      rover.postMessage({
        action: eventName,
      });
    } catch (e) {
      this.errorHandler(e as Error);
    }
  }

  static getRoverInstance(): any {
    return window?.webkit?.messageHandlers?.rover;
  }
}
