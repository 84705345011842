import React, { useMemo } from 'react';
import styled from 'styled-components';

import ScrollToError from '@rover/react-lib/src/components/utils/ScrollToError';
import { CLASS_NAMES } from '@rover/react-lib/src/constants/classNames.constants';
import hasFormError from '@rover/react-lib/src/utils/hasFormError';

import { Props } from './types';

const StyledForm = styled.form`
  width: 100%;
`;

const InnerForm = ({
  id,
  nested = false,
  children,
  submitted,
  parentFormIsSubmitted,
  handleSubmit,
  handleKeyPress,
  validationErrors,
  initialValidationErrors,
  ...other
}: Props): JSX.Element => {
  const isSubmitted = useMemo(
    () => (submitted || (nested && parentFormIsSubmitted)) ?? false,
    [submitted, nested, parentFormIsSubmitted]
  );

  return (
    <StyledForm
      {...other}
      id={id}
      onSubmit={handleSubmit}
      onKeyPress={handleKeyPress}
      as={nested ? 'div' : 'form'}
      {...(!nested && id ? { name: id } : {})}
    >
      {children}
      <ScrollToError
        shouldScroll={
          isSubmitted ? hasFormError(validationErrors || initialValidationErrors) : false
        }
        errorClass={CLASS_NAMES.FORM_ERROR_CLASSNAME}
      />
    </StyledForm>
  );
};

export default InnerForm;
