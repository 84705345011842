import qs from 'qs';

/**
 * Used to override the default axios query param serialization
 * Outputs params with multiple values as repeating queries like 'a=x&a=y&a=z'
 */
const paramsSerializer = (params: Record<string, string | string[]> | URLSearchParams): string => {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

export default paramsSerializer;
