/**
 * Returns styling for a close-to-browser-default focus outline.  Use with :focus-visible selectors
 * (or the FocusOutline helper for a prebuilt :focus-within selector) to give accessible focus outlines to inputs.
 *
 * @param options: object with optional zIndex and outlineOffset integers
 * @returns {*}
 */

export type FocusOutlineOptions = {
  zIndex?: number;
  outlineOffset?: number;
};

export default (options: FocusOutlineOptions = {}): string => {
  const defaults = {
    zIndex: 5,
    outlineOffset: 2,
  };
  const props = { ...defaults, ...options };
  return `
      z-index: ${props.zIndex};
      // Mozilla
      outline: 5px auto Highlight;
      // WebKit
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: ${props.outlineOffset}px;`;
};
