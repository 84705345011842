import userFactory from '@rover/react-lib/src/factories/userFactory';
import {
  CurrentUser,
  CurrentUserRealUser,
  CurrentUserViewSchema,
} from '@rover/rsdk/src/apiClient/latest';
import { getAxiosInstance } from '@rover/rsdk/src/modules/Network/axios';
import type { Snakeify } from '@rover/rsdk/src/modules/Network/object-utilities';
import { camelize } from '@rover/rsdk/src/modules/Network/object-utilities';
import { UTM_PARAMS } from '@rover/rsdk/src/modules/Network/utm';
import { fullApiUrl } from '@rover/utilities/apiUrl';

import DefaultAPIErrorHandler from './DefaultAPIErrorHandler';

export const CURRENT_USER_URL: string = fullApiUrl('frontend/current-user/');

const isCurrentUser = (data: CurrentUserViewSchema): data is CurrentUser => {
  return 'currentUser' in data;
};

export const get = async (): Promise<CurrentUserViewSchema> => {
  const ai = getAxiosInstance();

  try {
    const { data } = await ai.get<Snakeify<CurrentUserViewSchema>>(CURRENT_USER_URL);
    const camelData = camelize(data, true, UTM_PARAMS);

    if (!isCurrentUser(camelData)) {
      (camelData as CurrentUser).currentUser = userFactory();
      (camelData as CurrentUser & { realUser: CurrentUserRealUser }).realUser = userFactory();
    }

    return camelData;
  } catch (e: any) {
    return DefaultAPIErrorHandler(e, 'There was an error while fetching the current user.');
  }
};
export default {
  get,
};
