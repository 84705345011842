import React, { Children, Component, Context } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import type { Middleware, Reducer, Store } from 'redux';
import { applyMiddleware, combineReducers, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import ReduxStoreContext from '@rover/react-lib/src/context/ReduxStoreContext';
import { reducer as lifted } from '@rover/react-lib/src/lib/lifted-components/redux';
import { Query } from '@rover/utilities/query';

const DEFAULT_MIDDLEWARE = [thunk.withExtraArgument({})];
export const BROADCAST_CHANNEL = 'redux-provider';
export const InitialStateContext: Context<any> = React.createContext(undefined);

export const createStore = (
  reducers: Record<string, (state: any, action: any) => any>,
  // @ts-expect-error
  // eslint-disable-next-line no-underscore-dangle, rover/no-platform-specific-globals-or-imports, default-param-last
  initialState: any = window.__ROVER_INITIAL_DATA__ || {},
  // eslint-disable-next-line default-param-last
  middleware: Middleware[] = DEFAULT_MIDDLEWARE,
  name: string
): Store<any> =>
  reduxCreateStore(
    combineReducers({ ...reducers, lifted }),
    initialState,
    composeWithDevTools({
      name,
    })(applyMiddleware(...middleware))
  ) as any;

export type Props = {
  reducers: Record<string, Reducer<any>>;
  children: React.ReactNode;
  name?: string;
  sagas?: any[];
  routerProps?: RouteComponentProps<any>;
  store?: Store<any>;
  query?: Query;
  history?: History;
};

export type InnerProviderProp = Props & {
  initialState?: any;
};

class InnerProvider extends Component<InnerProviderProp> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  reducers: Record<string, Reducer<any>> = {};

  reduxStore: any;

  sagaMiddleWare:
    | (Middleware & {
        run: (...args: any[]) => any;
      })
    | undefined;

  constructor(props: InnerProviderProp) {
    super(props);

    if (props.store) {
      this.reduxStore = props.store;
    } else {
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.reducers = props.reducers;
      this.sagaMiddleWare = createSagaMiddleware();
      this.reduxStore = createStore(
        props.reducers,
        props.initialState,
        [
          thunk.withExtraArgument({
            routerProps: props.routerProps,
            query: props.query,
            history: props.history,
          }),
          this.sagaMiddleWare,
        ],
        props.name || ''
      );
      this.registerSagas();
    }
  }

  registerSagas() {
    if (this.sagaMiddleWare) {
      this.props.sagas?.forEach((saga) => {
        if (this.sagaMiddleWare) {
          this.sagaMiddleWare.run(saga, this.props.routerProps);
        }
      });
    }
  }

  getContext() {
    return {
      reduxStore: this.reduxStore,
    };
  }

  render() {
    return (
      <ReduxStoreContext.Provider value={this.getContext()}>
        {Children.only(this.props.children)}
      </ReduxStoreContext.Provider>
    );
  }
}

const Provider = ({
  reducers,
  children,
  name = '',
  sagas = [],
  routerProps,
  store,
  query,
  history,
}: Props): JSX.Element => {
  return (
    <InitialStateContext.Consumer>
      {(initialState): JSX.Element => (
        <InnerProvider
          reducers={reducers}
          name={name}
          sagas={sagas}
          routerProps={routerProps}
          store={store}
          query={query}
          history={history}
          initialState={initialState}
        >
          {children}
        </InnerProvider>
      )}
    </InitialStateContext.Consumer>
  );
};

export default Provider;
