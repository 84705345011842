import getStatsdTagForUrl from './getStatsdTagForUrl';

const axiosErrorHandler = (e?: any, PROFILER?: any, PROFILER_KEYS?: any) => {
  let error;

  if (e && e.config && e.config.method) {
    const isTimeout = e.code === 'ECONNABORTED';
    const apiUrl = getStatsdTagForUrl(e.config.url, 'No Match Found');
    const apiMethod = e.config.method.toUpperCase();

    if (isTimeout && PROFILER && PROFILER_KEYS) {
      PROFILER.increment({
        key: PROFILER_KEYS.API_CALL_TIMEOUT,
        tags: {
          apiMethod,
          apiUrl,
        },
      });
    }

    error = new Error(
      `axiosUtils ${isTimeout ? 'timed out' : 'errored'} while requesting "${apiMethod} ${
        e.config.url
      }": ${e.message}`
    );
  } else {
    error = new Error(e);
  }

  if (!(e.response && e.response.status)) throw error;
  throw e;
};

export default axiosErrorHandler;
