export enum CookiePurpose {
  StrictlyNecessary = 'strictly-necessary',
  Functional = 'functional',
  Marketing = 'marketing',
  Performance = 'performance',
}

export type CookieDomain =
  | 'localhost'
  | 'rover.local'
  | '.roverstaging.com'
  | '.rover.com'
  | '.app.github.dev';

export type GtmCookie = {
  purpose: CookiePurpose;
  key: string;
  value: string;
  domain: CookieDomain;
  max_age: number;
  path: string;
};
