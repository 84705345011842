/* eslint-disable no-console */
import { MessageDescriptor } from '@lingui/core';

import type { ApiErrorResponse } from '@rover/types';
import parseApiErrors from '@rover/utilities/parseApiErrors';

import APIError from './APIError';

type ParsedErrors = Array<string | MessageDescriptor>;

const DefaultAPIErrorHandler = (
  error: ApiErrorResponse | Error, // If the error happens during the API call, we'll get an ApiErrorResponse, if it happens in code, we'll get a generic Error
  defaultErrorMessage: MessageDescriptor | string = 'There was an error.'
): never => {
  if (!('response' in error)) throw error; // Generic Error, so no processing is necessary
  if (!error.response) throw error;

  const { response } = error;
  const errors: ParsedErrors = parseApiErrors(response);
  const message = errors.length ? (errors.pop() as string) : defaultErrorMessage;
  const errorType = response.data?.error_type;

  // FE-3685 Don't console.error during jest tests, it is noisy.
  if (!process.env.JEST_WORKER_ID) {
    console.error(
      `Error in DefaultAPIErrorHandler: STATUS: ${response.status}, MESSAGE: ${
        typeof message === 'string' ? message : message.id
      }`
    );
  }

  // @ts-expect-error
  throw new APIError(message, response.status, errorType); // we throw the Error so that a duck can catch it and handle it
};

export default DefaultAPIErrorHandler;
