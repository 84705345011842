import { createSelector, Selector as ReselectSelector } from 'reselect';

import invariant from '@rover/utilities/invariant';

import {
  ActionTypes,
  Dispatch,
  Duck,
  DuckOptions,
  EffectTypes,
  GlobalReduxState,
  SelectorTypes,
} from './types';

export const createDuck = <
  DuckState,
  DuckActions extends ActionTypes,
  DuckEffects extends EffectTypes,
  DuckSelectors extends SelectorTypes
>({
  initialState,
  rootSelector,
  name,
  actions,
  effects,
  selectors,
  externalActionHandlers,
}: DuckOptions<DuckState, DuckActions, DuckEffects, DuckSelectors>): Duck<
  DuckState,
  DuckActions,
  DuckEffects,
  DuckSelectors
> => {
  // Build the action creators using a namespaced
  // action type
  const duckActions: Partial<DuckActions> = {};
  Object.keys(actions).forEach((type: keyof DuckActions) => {
    const actionType = `${name}/${String(type)}`;
    // @ts-expect-error
    const actionCreator: DuckActions[typeof type] = (payload) => ({
      payload,
      type: actionType,
    });
    actionCreator.actionType = actionType;
    actionCreator.toString = () => actionType;
    duckActions[type] = actionCreator;
  });

  // Build selectors giving them access to the state and each other
  const duckSelectors: Partial<DuckSelectors> = {};
  const selector = rootSelector || ((s: GlobalReduxState) => s[name]);
  const getDuckState: ReselectSelector<GlobalReduxState, DuckState> = createSelector(
    [selector],
    (state: DuckState): DuckState => {
      invariant(
        state,
        `You have a missing reducer for duck: ${name}.${'\n'}Register it in the page's <Provider> component. More information here: https://roverdotcom.atlassian.net/wiki/spaces/TECH/pages/993396022/Connecting+components+to+state+management#The-Top-Level-Container`
      );
      return state;
    }
  );
  Object.keys(selectors).forEach((type: keyof DuckSelectors) => {
    // @ts-expect-error
    duckSelectors[type] = selectors[type](getDuckState, createSelector, duckSelectors);
  });

  // Build the effects by passing them the internal
  // actions and effects
  const duckEffects: Partial<DuckEffects> = {};
  Object.keys(effects).forEach((type: keyof DuckEffects) => {
    // @ts-expect-error
    duckEffects[type] =
      (payload) => (dispatch: Dispatch, getState: () => GlobalReduxState, extraArgs) => {
        const effect = effects[type](payload);
        return effect(
          dispatch,
          getState,
          duckActions as DuckActions,
          duckEffects as DuckEffects,
          duckSelectors as DuckSelectors,
          extraArgs
        );
      };
  });

  // Create the reducer
  const reducer = (
    state: DuckState = initialState, // eslint-disable-line default-param-last
    action: { type: string; payload?: unknown }
  ): DuckState => {
    if (externalActionHandlers && externalActionHandlers[action.type]) {
      return externalActionHandlers[action.type](action)(state);
    }
    if (action.type.includes(name)) {
      const actionType: keyof ActionTypes = action.type.replace(`${name}/`, '');
      const reduce: ((a) => (s: Readonly<DuckState>) => DuckState) | undefined =
        actions[actionType];
      if (reduce) {
        return reduce(action.payload)(state);
      }
    }
    return state;
  };

  return {
    actions: duckActions as DuckActions,
    effects: duckEffects as DuckEffects,
    selectors: duckSelectors as DuckSelectors,
    reducer,
  };
};
