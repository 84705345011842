/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { JSXElementConstructor, PureComponent } from 'react';
import isFunction from 'lodash-es/isFunction';
import mapValues from 'lodash-es/mapValues';
import { ActionCreator, Dispatch, Store } from 'redux';

import ReduxStoreContext from '@rover/react-lib/src/context/ReduxStoreContext';
import {
  ActionTypes,
  EffectTypes,
  ExtractActionPayloadType,
  ExtractEffectPayloadType,
  ExtractEffectReturnType,
} from '@rover/react-lib/src/lib/redux-duck';

import isShallowEqual from '../../../utils/isShallowEqual';

export type ActionProps<A extends ActionTypes> = {
  [Property in keyof A]: (payload: ExtractActionPayloadType<A[Property]>) => void;
};

export type EffectProps<E extends EffectTypes> = {
  [Property in keyof E]: (
    payload: ExtractEffectPayloadType<E[Property]>
  ) => ExtractEffectReturnType<E[Property]>;
};

type RenderPropArgument<
  Actions extends ActionTypes,
  Effects extends EffectTypes,
  Selector extends (...args: any) => any
> = ActionProps<Actions> &
  EffectProps<Effects> &
  ReturnType<Selector> & {
    dispatch: Dispatch<any>;
  };

type RenderProp<
  A extends ActionTypes,
  E extends EffectTypes,
  Selector extends (...args: any) => any
> = (arg: RenderPropArgument<A, E, Selector>) => React.ReactNode;

export type Props<
  Actions extends ActionTypes,
  Effects extends EffectTypes,
  Selector extends (...args: any) => any
> = {
  selector?: Selector;
  actions?: Actions;
  effects?: Effects;
  onMount?: ActionCreator<any> | Array<ActionCreator<any>>;
  onUnmount?: ActionCreator<any> | Array<ActionCreator<any>>;
  store?: Store<any>;
  children: RenderProp<Actions, Effects, Selector>;
};

export type State = {
  selectorProps: Record<string, any>;
  actionProps: Record<string, any>;
  effectProps: Record<string, any>;
  dispatch?: Dispatch<any>;
};

type StoreContextType = {
  reduxStore: Store<any>;
};

type InnerConnectProps<
  A extends ActionTypes,
  E extends EffectTypes,
  S extends (...args: any) => any
> = Props<A, E, S> & StoreContextType;

const getActions = <A extends ActionTypes>(
  actions: A,
  dispatch,
  { prevActions, prevDispatchActions }
): ActionProps<A> =>
  mapValues(actions, (action, name) => {
    if (!action || !isFunction(action)) {
      throw new Error(`Connect action values must be functions (action ${name})`);
    }

    return action === prevActions[name]
      ? prevDispatchActions[name]
      : (...args) => dispatch(action(...args));
  });

class InnerConnect extends PureComponent<InnerConnectProps<any, any, any>, State> {
  // eslint-disable-next-line class-methods-use-this
  unsubscribe: (...args: any[]) => any = () => {};

  static defaultProps = {
    selector: () => ({}),
    actions: {},
    effects: {},
    store: undefined,
    onMount: undefined,
    onUnmount: undefined,
  };

  state: State = {
    selectorProps: {},
    actionProps: {},
    effectProps: {},
  };

  prevActions = {};

  prevEffects = {};

  prevDispatchActions = {};

  prevDispatchEffects = {};

  UNSAFE_componentWillMount() {
    const { store } = this;
    const { onMount } = this.props;

    if (onMount) {
      if (Array.isArray(onMount)) {
        onMount.forEach((action) => {
          store.dispatch(action());
        });
      } else {
        store.dispatch(onMount());
      }
    }

    this.unsubscribe = store.subscribe(this.updateState);
    this.updateState();
    const { selector } = this.props;
    const nextSelectorProps = selector(store.getState() || {}, this.props);
    this.setState(() => ({
      selectorProps: nextSelectorProps,
      actionProps: this.getActions(),
      effectProps: this.getEffects(),
      dispatch: store.dispatch,
    }));
  }

  componentWillUnmount() {
    const { store } = this;
    this.unsubscribe();
    this.unmounted = true;
    const { onUnmount } = this.props;

    if (onUnmount) {
      if (Array.isArray(onUnmount)) {
        onUnmount.forEach((action) => {
          store.dispatch(action());
        });
      } else {
        store.dispatch(onUnmount());
      }
    }
  }

  unmounted = false;

  get store() {
    return this.props.store || this.props.reduxStore;
  }

  getActions = () => {
    const { store } = this;
    const dispatchActions = getActions(this.props.actions, store.dispatch, {
      prevActions: this.prevActions,
      prevDispatchActions: this.prevDispatchActions,
    });
    this.prevActions = this.props.actions;
    this.prevDispatchActions = dispatchActions;
    return dispatchActions;
  };

  getEffects = () => {
    const { store } = this;
    const dispatchEffects = getActions(this.props.effects, store.dispatch, {
      prevActions: this.prevEffects,
      prevDispatchActions: this.prevDispatchEffects,
    });
    this.prevEffects = this.props.effects;
    this.prevDispatchEffects = dispatchEffects;
    return dispatchEffects;
  };

  updateState = () => {
    const { store } = this;
    const { selector } = this.props;
    const nextSelectorProps = selector(store.getState() || {}, this.props);

    if (!isShallowEqual(nextSelectorProps, this.state.selectorProps)) {
      // NOTE: In a nested form, if there is an action that causes FormA to unmount FormB,
      // FormB's reducer will be cleared before the update arrive. Since unsubscribe does not
      // affect actions that are already in progress, this function will run even unmounted.
      if (this.unmounted) {
        return;
      }

      this.setState(() => ({
        // We recompute the selector props here rather than use nextSelectorProps since the value
        // may change while this running and cause selectors/props to be out of sync.
        selectorProps: selector(store.getState() || {}, this.props),
        actionProps: this.getActions(),
        effectProps: this.getEffects(),
        dispatch: store.dispatch,
      }));
    }
  };

  render() {
    return this.props.children({
      ...this.state.selectorProps,
      ...this.state.actionProps,
      ...this.state.effectProps,
      dispatch: this.state.dispatch,
    });
  }
}

const Connect = <A extends ActionTypes, E extends EffectTypes, S extends (...args: any) => any>({
  selector = (() => ({})) as S,
  actions = {} as A,
  effects = {} as E,
  ...other
}: Props<A, E, S>): JSX.Element => (
  <ReduxStoreContext.Consumer>
    {(context): JSX.Element => (
      <InnerConnect
        selector={selector}
        actions={actions}
        effects={effects}
        {...(context as StoreContextType)}
        {...other}
      />
    )}
  </ReduxStoreContext.Consumer>
);

export default Connect;

export type ContainerProps<
  C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
  A = Record<string, unknown>,
  E = Record<string, unknown>,
  S extends (...args: any[]) => Record<string, unknown> = () => Record<string, unknown>
> = Omit<React.ComponentProps<C>, keyof A | keyof E | keyof ReturnType<S>>;

/*
Usage:
  type Props = ContainerProps<
    typeof MyComponent,
    typeof actions,
    typeof effects,
    typeof selector
  >;
  const MyComponentContainer = (props: Props): JSX.Element => (
  <Connect actions={actions} effects={effects} selector={selector}>
    {(connectedProps) => <MyComponent {...connectedProps} {...props} />}
  </Connect>
);
*/
