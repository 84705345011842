const getCookieByName = (
  name: string,
  cookieString: string | null | undefined
): string | undefined => {
  // https://stackoverflow.com/a/15724300/796347
  const value = `; ${cookieString}`;
  const regex = new RegExp(`;.?${name}=`);
  const parts: string[] = value.split(regex);
  if (parts.length >= 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
};

export default getCookieByName;
