import type Mustache from 'mustache';

declare global {
  interface Window {
    Mustache: typeof Mustache;
  }
}

// Allow this file to be used external to Django templates, like our
// base.html that defines `Mustache.templates`
if (typeof window.Mustache === 'undefined') {
  (window.Mustache as any) = {};
}

// Mustache defines a `.to_html` function - perform the import
// and merge the objects if it's already defined.
if (typeof (window.Mustache as any).to_html === 'undefined') {
  // eslint-disable-next-line
  const MustacheModule = require('mustache');

  window.Mustache = { ...window.Mustache, ...MustacheModule };
}

export default window.Mustache;
