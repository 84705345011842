/* eslint-disable rover/no-platform-specific-globals-or-imports */
import EmbeddedEventStrategy from './embeddedEventStrategy';

export default class AndroidEmbeddedEventStrategy extends EmbeddedEventStrategy {
  static handlesEvent(
    eventName: string,
    {
      isAndroid,
    }: {
      isAndroid: boolean;
    }
  ): boolean {
    return isAndroid;
  }

  triggerEvent(eventName: string): void {
    const rover = AndroidEmbeddedEventStrategy.getRoverInstance();
    const serializedData = JSON.stringify({
      action: eventName,
    });

    try {
      rover.postMessage(serializedData);
    } catch (e) {
      this.errorHandler(e as Error);
    }
  }

  static getRoverInstance() {
    return window?.rover;
  }
}
