import { CookieDomain } from './types';

const LOCAL_HOST = 'localhost';
const LOCAL_ALIASES = [LOCAL_HOST, '127.0.0.1'];
const ROVER_LOCAL = 'rover.local';
const ROVER_CDN_STAGING = '.roverstaging.com';
const ROVER_COM = '.rover.com';
const GITHUB_PREVIEW = '.app.github.dev';

const getCookieDomain = (host: string): CookieDomain => {
  if (LOCAL_ALIASES.includes(host)) {
    return LOCAL_HOST;
  }

  if (host === ROVER_LOCAL) {
    return ROVER_LOCAL;
  }

  if (host.endsWith(ROVER_CDN_STAGING)) {
    return ROVER_CDN_STAGING;
  }

  if (host.includes(GITHUB_PREVIEW)) {
    return GITHUB_PREVIEW;
  }

  return ROVER_COM;
};

export default getCookieDomain;
