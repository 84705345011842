import React from 'react';
import styled from 'styled-components';
import { compose, space, variant } from 'styled-system';

import {
  A11yHidden,
  DSTokenMap,
  fontWeight,
  textAlign,
  textColor,
  textDecoration,
  ThemeHeadingSizes,
} from '@rover/kibble/styles';

import Box from '../Box';

import { HeadingCommonProps } from './Heading.common';

export type HeadingTags = 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type Props = HeadingCommonProps & {
  a11yHidden?: boolean;
  as?: HeadingTags;
  className?: string;
  responsive?: boolean;
  testID?: string;
  display?: string;
};

export const DEFAULT_TAGS_FOR_SIZES: Record<ThemeHeadingSizes, HeadingTags> = {
  '50': 'h6',
  '100': 'h5',
  '200': 'h4',
  '300': 'h3',
  '400': 'h2',
  '500': 'h1',
  '600': 'div',
};

export const getTag = (size: ThemeHeadingSizes, as?: HeadingTags): HeadingTags => {
  if (as) {
    return as;
  }

  return DEFAULT_TAGS_FOR_SIZES[size];
};

const StyledHeading = styled(Box)<Omit<Props, 'as'>>(
  // Resets values in case of global styles
  {
    padding: '0',
    margin: '0',
    border: '0 none',
  },
  (props) =>
    variant({
      prop: 'size',
      variants: {
        50: {
          fontWeight: DSTokenMap.HEADING_50_FONT_WEIGHT,
          fontSize: DSTokenMap.HEADING_50_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_50_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_50_MARGIN_BOTTOM,
        },
        100: {
          fontWeight: DSTokenMap.HEADING_100_FONT_WEIGHT,
          fontSize: DSTokenMap.HEADING_100_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_100_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_100_MARGIN_BOTTOM,
        },
        200: {
          fontWeight: DSTokenMap.HEADING_200_FONT_WEIGHT,
          fontSize: DSTokenMap.HEADING_200_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_200_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_200_MARGIN_BOTTOM,
        },
        300: {
          fontWeight: DSTokenMap.HEADING_300_FONT_WEIGHT,
          fontSize: DSTokenMap.HEADING_300_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_300_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_300_MARGIN_BOTTOM,
        },
        400: {
          fontWeight: DSTokenMap.HEADING_400_FONT_WEIGHT,
          fontSize: props.responsive
            ? [DSTokenMap.HEADING_400_FONT_SIZE, DSTokenMap.HEADING_RESPONSIVE_400_FONT_SIZE]
            : DSTokenMap.HEADING_400_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_400_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_400_MARGIN_BOTTOM,
        },
        500: {
          fontWeight: DSTokenMap.HEADING_500_FONT_WEIGHT,
          fontSize: props.responsive
            ? [DSTokenMap.HEADING_500_FONT_SIZE, DSTokenMap.HEADING_RESPONSIVE_500_FONT_SIZE]
            : DSTokenMap.HEADING_500_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_500_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_500_MARGIN_BOTTOM,
        },
        600: {
          fontWeight: DSTokenMap.HEADING_600_FONT_WEIGHT,
          fontSize: props.responsive
            ? [DSTokenMap.HEADING_600_FONT_SIZE, DSTokenMap.HEADING_RESPONSIVE_600_FONT_SIZE]
            : DSTokenMap.HEADING_600_FONT_SIZE,
          lineHeight: DSTokenMap.HEADING_600_LINE_HEIGHT,
          marginBottom: DSTokenMap.HEADING_600_MARGIN_BOTTOM,
        },
      },
    })(props),
  (props) => (props.a11yHidden ? `${A11yHidden}` : ''),
  compose(textAlign, textColor, textDecoration, fontWeight, space) // Apply last to avoid being overwritten by resets and variant styles
);

function Heading({
  a11yHidden = false,
  as,
  responsive = true,
  size = '200',
  ...other
}: Props): JSX.Element {
  return (
    <StyledHeading
      {...other}
      as={getTag(size, as)}
      a11yHidden={a11yHidden}
      responsive={responsive}
      size={size}
    />
  );
}

export default Heading;
