/* eslint-disable no-unused-vars, class-methods-use-this */

/**
 * This represents an abstract base class for strategies that support various clients
 */
export default class EmbeddedEventStrategy {
  errorHandler: (error: Error) => void;

  constructor(errorHandler: (error: Error) => void) {
    this.errorHandler = errorHandler;
  }

  static handlesEvent(
    eventName: string,
    {
      isAndroid,
      isiOS,
    }: {
      isAndroid: boolean;
      isiOS: boolean;
    }
  ): boolean {
    throw new TypeError('handlesEvent must be overridden in EmbeddedEventStrategy subclasses');
  }

  triggerEvent(eventName: string): void {
    throw new TypeError('triggerEvent must be overridden in EmbeddedEventStrategy subclasses');
  }

  static getRoverInstance(): any {
    throw new TypeError('getRoverInstance must be overridden in EmbeddedEventStrategy subclasses');
  }
}
