import React, { Component } from 'react';
import mergeWith from 'lodash-es/mergeWith';

import { FormErrors, FormValidators, FormValues, RecursivePartial } from '@rover/types/src/Form';

import InnerForm from './InnerForm/InnerForm';
import * as validators from './validation/validators';
import * as validationUtils from './validation';

export const mergeFormData = <FV extends FormValues>(
  data: FV,
  ...args: Array<FV | RecursivePartial<FV> | undefined>
): FV =>
  mergeWith({}, data, ...args, (a, b) => {
    if (Array.isArray(b)) {
      return b;
    }

    return undefined;
  });

export type RenderPropArgs<FV extends FormValues, FVD extends FormValidators<FV>> = {
  data: FV;
  onChange: (updates: RecursivePartial<FV>) => void;
  validationErrors: FormErrors<FV, FVD>;
  onSubmit: () => any;
  submitted: boolean;
  valid: boolean;
  clearData: () => void;
  resetValidation: (callback?: () => void) => void;
  validate: () => void;
};

export type Props<FV extends FormValues, FVD extends FormValidators<FV>> = {
  initialData: FV;
  validators: FVD | ((initialData: FV) => FVD);
  onSubmit?: (values: FV) => any;
  children: (form: RenderPropArgs<FV, FVD>) => JSX.Element;
  nested?: boolean;
  validateOnChange?: boolean;
  onChange?: (updates: {
    data: FV;
    validationErrors: FormErrors<FV, FVD> | undefined;
    valid: boolean;
  }) => void;
  onPreSubmit?: (valid: boolean, validationErrors: FormErrors<FV, FVD>) => any;
  onKeyPress?: (e: React.SyntheticEvent) => void;
  id?: string;
  parentFormIsSubmitted?: boolean;
};

export type State<FV extends FormValues, FVD extends FormValidators<FV>> = {
  initialValidationErrors: FormErrors<FV, FVD>;
  data: RecursivePartial<FV> | undefined;
  submitted: boolean;
  validationErrors: FormErrors<FV, FVD> | undefined;
  valid: boolean;
};

export default class Form<
  FV extends FormValues,
  FVD extends FormValidators<FV> = FormValidators<FV>
> extends Component<Props<FV, FVD>, State<FV, FVD>> {
  static defaultProps = {
    validators: {},
    onSubmit: (): void => {},
    nested: false,
    validateOnChange: false,
    onChange: (): void => {},
    onPreSubmit: (): void => {},
    onKeyPress: (): void => {},
  };

  static validators = validators;

  state = {
    initialValidationErrors: validationUtils.initializeValidation(
      this.props.initialData,
      this.props.validators
    ),
    data: undefined,
    submitted: false,
    validationErrors: undefined,
    valid: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props<FV, FVD>): void {
    const { initialData, validators: formValidators } = nextProps;
    const { data, valid } = this.state;

    if (!data && !valid) {
      const { valid: nextValid, errors } = validationUtils.validate(initialData, formValidators);
      this.setState(() => ({
        valid: nextValid,
        validationErrors: errors,
      }));
    }
  }

  handleChange = (updates: RecursivePartial<FV>): void => {
    const { validators: formValidators, initialData, validateOnChange, onChange } = this.props;
    const { valid, data, validationErrors } = this.state;
    const nextDirtyData = mergeFormData(data as unknown as FV, updates);
    const mergedData: FV = mergeFormData(initialData, nextDirtyData);
    let nextValid = valid;
    let nextErrors: FormErrors<FV, FVD> | undefined = validationErrors;
    let nextState = {};

    // Do validation
    if (validateOnChange || !valid) {
      const validationResults = validationUtils.validate(mergedData, formValidators);
      nextValid = validationResults.valid;
      nextErrors = validationResults.errors;
      nextState = {
        valid: nextValid,
        validationErrors: nextErrors,
      };
    }

    nextState = {
      ...nextState,
      data: nextDirtyData,
      submitted: false,
    };
    this.setState(nextState);

    if (onChange) {
      onChange({
        data: mergedData,
        validationErrors: nextErrors,
        valid: nextValid,
      });
    }
  };

  handleSubmit = (e?: React.SyntheticEvent): boolean => {
    const { initialData, onSubmit, onPreSubmit } = this.props;
    const { data } = this.state;
    const mergedFormData = mergeFormData(initialData, data);
    const { valid, errors } = validationUtils.validate(mergedFormData, this.props.validators);

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // validate needs to fire to set the flags correctly no matter it is valid or not
    this.setState(() => ({
      valid,
      validationErrors: errors,
      submitted: true,
    }));

    if (onPreSubmit) {
      onPreSubmit(valid, errors);
    }

    if (valid && onSubmit) {
      return onSubmit(mergedFormData);
    }

    return valid;
  };

  handleKeyPress = (e: React.KeyboardEvent): void => {
    const { onKeyPress } = this.props;

    if (onKeyPress) {
      onKeyPress(e);
    } else if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.handleSubmit();
    }
  };

  handleClearData = (): void => {
    this.setState(() => ({
      data: undefined,
      submitted: false,
      validationErrors: undefined,
      valid: true,
    }));
  };

  handleResetValidation = (callback?: () => void): void => {
    this.setState(
      () => ({
        submitted: false,
        validationErrors: undefined,
        valid: true,
      }),
      callback
    );
  };

  handleValidation = (): void => {
    const { validators: formValidators, initialData } = this.props;
    const { data } = this.state;
    const mergedFormData = mergeFormData(initialData, data);
    const { valid, errors } = validationUtils.validate(mergedFormData, formValidators);
    this.setState(() => ({
      valid,
      validationErrors: errors,
    }));
  };

  render(): JSX.Element {
    const {
      id,
      validators: v, // eslint-disable-line  @typescript-eslint/no-unused-vars
      initialData,
      children,
      onSubmit, // eslint-disable-line  @typescript-eslint/no-unused-vars
      nested,
      onChange, // eslint-disable-line  @typescript-eslint/no-unused-vars,
      parentFormIsSubmitted,
      ...other
    } = this.props;
    const { initialValidationErrors, data, submitted, valid, validationErrors } = this.state;
    const { handleSubmit, handleKeyPress } = this;

    return (
      <InnerForm
        id={id}
        nested={nested}
        submitted={submitted}
        parentFormIsSubmitted={parentFormIsSubmitted ?? false}
        handleSubmit={handleSubmit}
        handleKeyPress={handleKeyPress}
        validationErrors={validationErrors}
        initialValidationErrors={initialValidationErrors}
        {...other}
      >
        {children({
          submitted,
          valid,
          data: mergeFormData(initialData, data),
          validationErrors: validationErrors || initialValidationErrors,
          onChange: this.handleChange,
          onSubmit: this.handleSubmit,
          clearData: this.handleClearData,
          resetValidation: this.handleResetValidation,
          validate: this.handleValidation,
        })}
      </InnerForm>
    );
  }
}
