export enum EmailVerificationStatus {
  COMPULSORY = 'compulsory',
  OPTIONAL = 'optional',
  NOT_REQUIRED = 'not_required',
}

export type EmailVerification = {
  code: string;
  emailVerificationStatus?: string;
};
