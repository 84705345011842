import type { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';

import { isOverlappingTime } from '@rover/react-lib/src/components/datetime/TimePickerDay/getOverlappingTimes';
import { getTimeFormat } from '@rover/react-lib/src/factories/timeFactory';
import { isSameDay, isTimeInThePastForToday } from '@rover/react-lib/src/utils/datetime';
import { isBeforeTime } from '@rover/react-lib/src/utils/time';
import { FuzzyTime } from '@rover/types';
import { FormError, FormValidator, FormValidatorCreator } from '@rover/types/src/Form';
import { getWordCount } from '@rover/utilities/wordCount';

export const required: FormValidatorCreator =
  (message: MessageDescriptor = t`This field is required.`) =>
  (prop: unknown) => {
    if (prop === 0 || prop === false) return null;
    if (typeof prop === 'string' && !prop.trim()) return message;
    return prop ? null : message;
  };

export const truthy: FormValidatorCreator =
  (message: MessageDescriptor = t`This field must be truthy.`) =>
  (prop) =>
    prop ? null : message;

export const agreeToTos: FormValidatorCreator =
  (message: MessageDescriptor = t`Please agree to our Terms of Service and Privacy Statement.`) =>
  (prop) =>
    prop ? null : message;

export const minValue: FormValidatorCreator =
  (value: number, message: MessageDescriptor = t`This field must be at least ${value}.`) =>
  (prop) => {
    if (!prop && prop !== 0) return message;
    return Number(prop) >= value ? null : message;
  };

export const minDate: FormValidatorCreator =
  (value: Date, message: MessageDescriptor = t`This field must be at least ${value}.`) =>
  (prop: Date) => {
    return prop >= value ? null : message;
  };

export const maxDate: FormValidatorCreator =
  (value: Date, message: MessageDescriptor = t`This field must be no later than ${value}.`) =>
  (prop: Date) => {
    return prop < value ? null : message;
  };

export const maxValue: FormValidatorCreator =
  (value: number, message: MessageDescriptor = t`This field must be no more than ${value}.`) =>
  (prop) => {
    if (!prop && prop !== 0) return message;
    return Number(prop) <= value ? null : message;
  };

export const minLength: FormValidatorCreator =
  (
    length = 10,
    message: MessageDescriptor = t`This field must be at least ${length} characters long.`
  ) =>
  (prop: any) => {
    if (!prop) return message;
    return prop.length >= length ? null : message;
  };

export const maxLength: FormValidatorCreator =
  (
    length: number,
    message: MessageDescriptor = t`This field must be at most ${length} characters long.`
  ) =>
  (prop) => {
    if (typeof prop !== 'string') return message;
    return prop.length <= length ? null : message;
  };

export const withinInclusiveRange: FormValidatorCreator =
  (
    min: number,
    max: number,
    message: MessageDescriptor = t`This field must be between ${min} and ${max}.`
  ) =>
  (prop) => {
    if (typeof prop !== 'number') return message;
    const meetsMin = min <= prop;
    const meetsMax = max >= prop;
    return meetsMin && meetsMax ? null : message;
  };

export const minWordCount: FormValidatorCreator =
  (length: number, message: MessageDescriptor = t`Please write at least ${length} words.`) =>
  (prop) => {
    if (typeof prop !== 'string') return message;
    const wordCount = getWordCount(prop);
    const meetsWordCount = typeof wordCount === 'number' && wordCount >= length;
    return meetsWordCount ? null : message;
  };

export const zipcode: FormValidatorCreator =
  (regex?: RegExp | null | undefined, message: MessageDescriptor = t`Enter a valid zipcode.`) =>
  (prop) => {
    if (!prop || typeof prop !== 'string') return message;
    const zipcodeRegex =
      regex || /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    return zipcodeRegex.test(prop.trim()) ? null : message;
  };

/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email */
// eslint-disable-next-line prefer-regex-literals
const emailTestRegexp = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);
export const email: FormValidatorCreator =
  (message: MessageDescriptor = t`Please enter a valid email address`): FormValidator =>
  (prop: unknown): FormError =>
    typeof prop === 'string' && emailTestRegexp.test(prop) ? null : message;

export const notEmptyArray: FormValidatorCreator =
  (message: MessageDescriptor = t`This field is required.`) =>
  (prop) =>
    Array.isArray(prop) && prop.length > 0 ? null : message;

export const cardHolderName: FormValidatorCreator =
  (message: MessageDescriptor = t`We cannot store names with only numbers in our system.`) =>
  (prop) =>
    typeof prop === 'string' && /^[\d\s-]+$/.test(prop) ? message : null;

export const noUndefinedTimes: FormValidatorCreator =
  (message: MessageDescriptor = t`Please select a time.`) =>
  (days) => {
    if (!Array.isArray(days)) return [[message]];
    const errors: Array<Array<MessageDescriptor | null>> = [];
    let isError = false;
    days.forEach((day) => {
      const dayErrors: Array<MessageDescriptor | null> = [];
      day.times.forEach((time) => {
        if (!time.value) {
          isError = true;
          dayErrors.push(message);
        } else {
          dayErrors.push(null);
        }
      });
      errors.push(dayErrors);
    });
    return isError ? errors : null;
  };

export const noPastDaysTimesForToday: FormValidatorCreator =
  (message: MessageDescriptor = t`Time cannot be in the past`) =>
  (days) => {
    if (!Array.isArray(days)) return null;
    const errors: Array<Array<MessageDescriptor | null>> = [];
    let isError = false;
    days.forEach((day) => {
      const dayErrors: Array<MessageDescriptor | null> = [];
      day.times.forEach((time) => {
        if (isTimeInThePastForToday(day.date, time?.value)) {
          isError = true;
          dayErrors.push(message);
        } else {
          dayErrors.push(null);
        }
      });
      errors.push(dayErrors);
    });
    return isError ? errors : null;
  };

export const someDaysWithTimes: FormValidatorCreator =
  (message: MessageDescriptor = t`Please add at least one day with times`) =>
  (days) => {
    if (!Array.isArray(days)) return message;
    const daysWithTimes = days.some((day) => day.times && day.times.length > 0);
    return daysWithTimes ? null : message;
  };

export const maxNumberOfTimesPerDay: FormValidatorCreator =
  (
    value: number,
    message: MessageDescriptor = t`You can only schedule up to ${value} times per day.`
  ) =>
  (days) => {
    if (!Array.isArray(days)) return [[message]];
    const errors: Array<Array<MessageDescriptor | null>> = [];
    let isError = false;
    days.forEach((day) => {
      const dayErrors: Array<MessageDescriptor | null> = [];

      if (Array.isArray(day.times) && day.times.length > value) {
        isError = true;
        dayErrors.push(message);
      } else {
        dayErrors.push(null);
      }

      errors.push(dayErrors);
    });
    return isError ? errors : null;
  };

export const overlappingOfTimesPerDay: FormValidatorCreator =
  (timeSelectionDuration: number, message: MessageDescriptor = t`Time ranges cannot overlap.`) =>
  (days) => {
    if (!Array.isArray(days)) return [[message]];
    const errors: Array<Array<MessageDescriptor | null>> = [];
    let isError = false;
    days.forEach((day) => {
      const dayErrors: Array<MessageDescriptor | null> = [];
      day.times.forEach((_time, idx) => {
        if (isOverlappingTime(idx, day.times, timeSelectionDuration)) {
          isError = true;
          dayErrors.push(message);
        } else {
          dayErrors.push(null);
        }
      });
      errors.push(dayErrors);
    });
    return isError ? errors : null;
  };

export const requiredFuzzyTime: FormValidatorCreator =
  (message: MessageDescriptor = t`Please select a start and end time.`) =>
  (fuzzyTime) =>
    fuzzyTime &&
    typeof fuzzyTime === 'object' &&
    fuzzyTime.earliest &&
    fuzzyTime.earliest.value &&
    fuzzyTime.latest &&
    fuzzyTime.latest.value
      ? null
      : message;

export const requiredPeriod: FormValidatorCreator =
  (
    pickUpDate: { date: Date | null; time: FuzzyTime | Partial<FuzzyTime> },
    dropOffDate: { date: Date | null; time: FuzzyTime | Partial<FuzzyTime> },
    message: MessageDescriptor = t`Select a start and end date`
  ) =>
  () =>
    pickUpDate &&
    dropOffDate &&
    pickUpDate?.date !== null &&
    typeof pickUpDate?.date !== 'undefined' &&
    dropOffDate?.date !== null &&
    typeof dropOffDate?.date !== 'undefined'
      ? null
      : message;

export const requiredStartTime: FormValidatorCreator =
  (message: MessageDescriptor = t`Select a start time`) =>
  (time) =>
    time && typeof time === 'object' && time.earliest && time.earliest.value ? null : message;

export const requiredEndTime: FormValidatorCreator =
  (message: MessageDescriptor = t`Select an end time`) =>
  (time) =>
    time && typeof time === 'object' && time.earliest && time.earliest.value ? null : message;

export const noPastTimeForToday: FormValidatorCreator =
  (date: { date: Date }, message: MessageDescriptor = t`Time cannot be in the past`) =>
  (time) => {
    if (isTimeInThePastForToday(date.date, time?.earliest?.value)) {
      return message;
    }
    return null;
  };

export const fuzzyTimeRangeValidator: FormValidatorCreator =
  (message: MessageDescriptor = t`The start time must be before the end time`) =>
  (pudo: { date?: Date; time: FuzzyTime | Partial<FuzzyTime> }) => {
    if (
      pudo.time.earliest &&
      pudo.time.latest &&
      isBeforeTime(pudo.time.latest, pudo.time.earliest)
    ) {
      return message;
    }
    return null;
  };

export const requiredDropOffBeforePickup: FormValidatorCreator =
  (
    pickUp: { date: Date; time: FuzzyTime | Partial<FuzzyTime> },
    dropOff: { date: Date; time: FuzzyTime | Partial<FuzzyTime> },
    message: MessageDescriptor = t`Must be after start time`,
    validateOnlyWhenDatesSelected: boolean = false
  ) =>
  () => {
    /* We always want to ensure that drop-off is before pick-up for Doggy Daycare (since the service starts and
    ends the same day) but for Boarding & House Sitting only validate the times when dates are actually selected. */
    if (validateOnlyWhenDatesSelected) {
      if (!pickUp?.date || !dropOff?.date) {
        return null;
      }
    }
    const pickUpDate = pickUp?.date ?? moment().toDate();
    const dropOffDate = dropOff?.date ?? moment().toDate();
    if (isSameDay(pickUpDate, dropOffDate)) {
      if (dropOff?.time?.earliest?.value && pickUp?.time?.earliest?.value) {
        if (
          moment(dropOff.time.earliest.value, getTimeFormat()).isSameOrAfter(
            moment(pickUp.time.earliest.value, getTimeFormat())
          )
        ) {
          return message;
        }
      }
    }
    return null;
  };

export const fieldsMatch: FormValidatorCreator =
  (matchFieldValue: any, message: MessageDescriptor = t`Fields must match.`) =>
  (prop: any) =>
    prop === matchFieldValue ? null : message;

export const noSpecialCharactersOnfidoNames: FormValidatorCreator =
  (message: MessageDescriptor = t`Special characters are not allowed`) =>
  (prop) =>
    /^[\p{L}\-—–,.''‘’ ]+$/u.test(prop) ? null : message;

export const noNumbers: FormValidatorCreator =
  (message: MessageDescriptor = t`Numbers are not allowed`) =>
  (prop) =>
    !/^([^0-9]*)$/.test(prop) ? message : null;

export const noDoubleWhiteSpaces: FormValidatorCreator =
  (message: MessageDescriptor = t`Duplicate whitespaces are not allowed`) =>
  (prop) =>
    /\s{2,}/g.test(prop) ? message : null;
