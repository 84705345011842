import { MessageDescriptor } from '@lingui/core';

export default class APIError extends Error {
  status: number;

  errorType?: string;

  constructor(message: MessageDescriptor | string, status: number, errorType?: string) {
    super(typeof message !== 'string' ? message.id : message);
    this.status = status;
    this.errorType = errorType;
  }
}
