import Enum from '@rover/types/src/Enum';

export class APIVersion extends Enum<string> {
  static v3 = new APIVersion('v3');

  static v4 = new APIVersion('v4');

  static v5 = new APIVersion('v5');

  static v6 = new APIVersion('v6');

  static v7 = new APIVersion('v7');
}
