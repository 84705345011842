import i18n from '../translation';

import weekContextless from './weekContextless';

const pluralDaysOfWeek = [
  i18n._('Sundays'),
  i18n._('Mondays'),
  i18n._('Tuesdays'),
  i18n._('Wednesdays'),
  i18n._('Thursdays'),
  i18n._('Fridays'),
  i18n._('Saturdays'),
];
export default class Week extends weekContextless {
  // e.g. 'Sundays'
  static upperEnglishPluralLongName(dayNumber: number): string {
    const daysOrder = this.getDaysOrderByFirstDayOfWeek();
    return pluralDaysOfWeek[daysOrder[dayNumber]];
  }
}
