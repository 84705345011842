import {
  CookiePurpose,
  createCookie,
  getCookie,
  removeCookie,
} from '@rover/rsdk/src/modules/Network/cookies';

let supported = true;
const STORAGE_KEY = 'rover_storage_fallback';

// Test all features of localStorage to determine browser compatibility.
// If incompatible, fallback to cookie based-storage.
try {
  const key = `rover_storage_test_${Math.round(Math.random() * 1e7)}`;
  window.localStorage.setItem(key, 'test');
  window.localStorage.removeItem(key);
} catch (e) {
  supported = false;
}

const createStorage = (id: string, expirationDays = 0): Storage => ({
  get length(): number {
    const data = JSON.parse(getCookie(id) || '{}');
    return Object.keys(data).length;
  },
  key(index): string | null {
    const data = JSON.parse(getCookie(id) || '{}');
    return Object.keys(data)[index] ?? null;
  },
  setItem(key, value): void {
    const data = JSON.parse(getCookie(id) || '{}');
    data[key] = value;
    createCookie(CookiePurpose.StrictlyNecessary, id, JSON.stringify(data), expirationDays);
    return data[key];
  },
  getItem(key): string | null {
    const data = JSON.parse(getCookie(id) || '{}');
    return data[key] ?? null;
  },
  removeItem(key): void {
    const data = JSON.parse(getCookie(id) || '{}');
    delete data[key];
    createCookie(CookiePurpose.StrictlyNecessary, id, JSON.stringify(data), expirationDays);
    return undefined;
  },
  clear(): void {
    removeCookie(id);
  },
});

export const localStorage: Storage = supported
  ? window.localStorage
  : createStorage(`localStorage_${STORAGE_KEY}`, 365);
export const sessionStorage: Storage = supported
  ? window.sessionStorage
  : createStorage(`sessionStorage_${STORAGE_KEY}`, 0);
