import FocusOutlineStyle, { FocusOutlineOptions } from './FocusOutlineStyle';

/**
 * Adds a :focus-within pseudoselector to a component with close-to-browser-default focus outline.
 *
 * Use when a custom component wraps a focusable input but doesn't display it: while the input is
 * focused, the component will show a focus outline.
 *
 * @param options: object with optional zIndex and outlineOffset integers
 * @returns {*}
 */

export default function FocusOutline(options: FocusOutlineOptions = {}): string {
  return `
    &:focus-within {
      ${FocusOutlineStyle(options)}
    }`;
}
