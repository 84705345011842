import { i18n, MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import { AxiosRequestConfig } from 'axios';

import { Logger } from '@rover/rsdk/src/modules/Logging';

export const DEFAULT_ERROR_MESSAGE = t`We were unable to process your request. Please try again shortly or contact Customer Support.`;

export const DEFAULT_CF_ERROR_MESSAGE = t`We were unable to process your request. Please try again shortly or contact Customer Support. (Error code: #15172)`;

const createThrottleError = (msg: string) => {
  const digitsMatcher = msg.match(/\d+/);
  const matchedDigits: string = digitsMatcher ? digitsMatcher[0] : '';
  const seconds = parseInt(matchedDigits, 10);

  if (Number.isNaN(seconds)) {
    return t`Please slow down and try again in a few minutes.`;
  }

  const minutes = parseInt((seconds / 60).toString(), 10);

  if (minutes > 1000) {
    return t`You have performed this action too many times. Please try again tomorrow.`;
  }

  return t`Please slow down and try again in a few minutes.`;
};

const parseObjectErrors = (
  json: Record<string, unknown> = {}
): Array<string | MessageDescriptor> => {
  const errors: Array<string | MessageDescriptor> = [];

  let { error_description: errorDescription, non_field_errors: nonFieldErrors, error } = json;
  const { detail, error_type: errorType } = json;

  if (detail) {
    errors.push(String(detail));
  }

  if (error && typeof error === 'object' && errorType !== 'field_validation') {
    const errorData = error as Record<string, unknown>;
    if (errorData.error_description) {
      errorDescription = errorData.error_description;
    } else if (errorData.error_status) {
      errorDescription = errorData.error_status;
    }

    if (errorData.non_field_errors) nonFieldErrors = errorData.non_field_errors;
    if (!errorDescription && !nonFieldErrors) {
      error = JSON.stringify(error);
    }
  }

  if (errorDescription) errors.push(String(errorDescription));
  if (typeof error === 'string') errors.push(error);

  if (Array.isArray(nonFieldErrors)) {
    errors.push(...nonFieldErrors);
  } else if (nonFieldErrors) {
    errors.push(String(nonFieldErrors));
  }

  if (!errors.length && typeof json === 'object') {
    Object.keys(json).forEach((errorKey: string) => {
      let fieldErrors: Array<string | MessageDescriptor> = [];
      if (Array.isArray(json[errorKey])) {
        const jsonArray = json[errorKey] as Array<Record<string, unknown>>;
        jsonArray.forEach((err) => {
          fieldErrors.push(...parseObjectErrors(err));
        });
      } else {
        fieldErrors = parseObjectErrors(json[errorKey] as Record<string, unknown>);
      }

      if (fieldErrors.length) {
        errors.push(...fieldErrors);
      } else {
        errors.push(`${json[errorKey]}`);
      }
    });
  }

  return errors;
};

export type Response = {
  status?: number;
  data?: Record<string, unknown> | string;
  config?: AxiosRequestConfig<any>;
  response?: Record<string, unknown>;
  responseText?: string;
  headers?: Headers | Record<string, string>;
};

const parseApiErrors = (
  response: Response = {},
  useAPIErrorFor: number[] = []
): Array<string | MessageDescriptor> => {
  const defaultGenericErrorCodes = [403, 405, 422, 500];
  const codesWithGenericError = defaultGenericErrorCodes.filter(
    (code) => !useAPIErrorFor.includes(code)
  );
  const errors: Array<string | MessageDescriptor> = [];
  let json;
  const status = response.status ? response.status : 0;
  const headers = response.headers || response?.config?.headers;
  const isHTML = (headers?.['Content-Type'] || headers?.['content-type'] || '').includes(
    'text/html'
  );

  if (status === 429) {
    const msg = response.data && typeof response.data === 'object' ? response.data.detail : '';
    errors.push(createThrottleError(String(msg)));
  } else if (status === 403 && headers?.['cf-mitigated'] === 'challenge') {
    // CF challenge flow
    errors.push(DEFAULT_CF_ERROR_MESSAGE);
    Logger.warn(i18n._(DEFAULT_CF_ERROR_MESSAGE), {
      path: response?.config?.url || 'unkown',
      headers: JSON.stringify(headers),
      env: process.env.JS_ENV_SERVER ? 'Server-Side' : 'Client-Side',
    });
  } else if (status === 403 && isHTML) {
    // CF block flow
    errors.push(`Non-JSON response, with these headers: ${Object.keys(headers || {})}`);
    Logger.warn('Non-JSON response error', {
      path: response?.config?.url || 'unkown',
      headers: JSON.stringify(headers),
      env: process.env.JS_ENV_SERVER ? 'Server-Side' : 'Client-Side',
    });
  } else if (codesWithGenericError.includes(status)) {
    errors.push(DEFAULT_ERROR_MESSAGE);
  } else {
    json =
      response.response ||
      (response.responseText && JSON.parse(response.responseText)) ||
      response.data;
  }

  if (json) {
    errors.push(...parseObjectErrors(json));
  }

  return errors;
};

export default parseApiErrors;
