import { clear as clearLocalStorage } from '@rover/utilities/localStorage';
import { clear as clearSessionStorage } from '@rover/utilities/sessionStorage';

export type LogoutConfig = {
  nextLocation: string;
  ref?: string;
  beforeNavCallback?: () => void;
};
export default async function ({
  nextLocation,
  ref,
  beforeNavCallback,
}: LogoutConfig): Promise<void> {
  clearLocalStorage();
  clearSessionStorage();
  beforeNavCallback && beforeNavCallback();
  const encodedLocation = encodeURIComponent(nextLocation);
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  window.location.href = `/logout/?next=${encodedLocation}${ref ? `&${ref}` : ''}`; // This is the actual logout
}
