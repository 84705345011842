import type { ComponentType, ReactElement, ReactNode } from 'react';
import React, { useId } from 'react';
import { MessageDescriptor } from '@lingui/core';
import classNames from 'classnames';
import styled from 'styled-components';

import { Box } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

import { PopoverPlacement } from '../../callouts/Popover/popover.constants';
import FormGroup from '../../formFields/FormGroup';
import FormValidationError from '../../formFields/FormValidationError';
import FormBasicValidationError from '../../formFields/FormValidationError/FormBasicValidationError';

// The empty object below is fine, we're not using it as a type, just as a param default
// eslint-disable-next-line @typescript-eslint/ban-types
export type Props<AdditionalInputComponentProps extends Record<string, unknown> = {}> = Omit<
  AdditionalInputComponentProps,
  'InputComponent'
> & {
  id?: string;
  name?: string;
  children?: (arg0: any) => JSX.Element;
  label?: ReactNode;
  subLabel?: ReactNode;
  subLabelPosition?: 'above' | 'below' | 'right';
  placeholder?: string;
  className?: string;
  required?: boolean;
  validationError?: MessageDescriptor | string | null;
  validationErrorAriaLabel?: string;
  validationErrorPlacement?: PopoverPlacement;
  validationType?: 'popover' | 'inline';
  style?: unknown;
  inputRef?: (arg0: ReactElement<any>) => void;
  InputComponent?: ComponentType<any>;
  useFieldset?: boolean;
  useLabelElementAsCaption?: boolean;
  canDismissValidationTip?: boolean;
  useKibbleStandardSizeStyle?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLLabelElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLLabelElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLLabelElement>;
};
export const Label = styled.label`
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  margin-bottom: ${DSTokenMap.SPACE_1X.toString()};
`;
export const KibbleStandardSizeLabel = styled.label`
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  margin-bottom: ${DSTokenMap.SPACE_1X.toString()};
  font-size: 13px;
`;
export const SubLabel = styled.div`
  line-height: 1.6;
  color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  margin-bottom: 6px;
`;
export const KibbleStandardSizeSubLabel = styled.div`
  line-height: 1.6;
  color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  margin-bottom: 6px;
  font-size: 13px;
`;
const RightLabel = styled.div`
  line-height: 1.6;
  color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  margin-left: ${DSTokenMap.SPACE_4X.toString()};
`;
const KibbleStandardSizeRightLabel = styled.div`
  line-height: 1.6;
  color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  margin-left: ${DSTokenMap.SPACE_4X.toString()};
  font-size: 13px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const getInputId = (componentId: string, name?: string, idProp?: string) =>
  idProp || `${name || ''}-${componentId}`;

const getFieldsetLegendId = (componentId: string, name?: string, idProp?: string) =>
  `form-group-${getInputId(componentId, name, idProp)}`;

const renderChildrenComponent = (props: Props, childrenComponent: ReactNode): JSX.Element => {
  const {
    subLabel,
    subLabelPosition,
    validationError,
    validationErrorAriaLabel,
    validationErrorPlacement,
    validationType,
    canDismissValidationTip,
    useKibbleStandardSizeStyle,
  } = props;

  if (subLabel && subLabelPosition === 'right') {
    if (validationError && validationType === 'inline') {
      return (
        <Box>
          <FlexRow>
            {childrenComponent}
            {!useKibbleStandardSizeStyle && <RightLabel>{subLabel}</RightLabel>}
            {useKibbleStandardSizeStyle && (
              <KibbleStandardSizeRightLabel>{subLabel}</KibbleStandardSizeRightLabel>
            )}
          </FlexRow>
          {validationError && <FormBasicValidationError errorMessage={validationError} />}
        </Box>
      );
    }
    return (
      <FlexRow>
        <FormValidationError
          canDismiss={canDismissValidationTip}
          message={validationError}
          placement={validationErrorPlacement}
        >
          {childrenComponent}
        </FormValidationError>
        {!useKibbleStandardSizeStyle && <RightLabel>{subLabel}</RightLabel>}
        {useKibbleStandardSizeStyle && (
          <KibbleStandardSizeRightLabel>{subLabel}</KibbleStandardSizeRightLabel>
        )}
      </FlexRow>
    );
  }

  return validationType === 'inline' ? (
    <Box>
      {childrenComponent}
      {validationError && (
        <FormBasicValidationError
          errorMessageArialabel={validationErrorAriaLabel}
          errorMessage={validationError}
        />
      )}
    </Box>
  ) : (
    <FormValidationError
      canDismiss={canDismissValidationTip}
      message={validationError}
      placement={validationErrorPlacement}
    >
      {childrenComponent}
    </FormValidationError>
  );
};

// The empty object below is fine, we're not using it as a type, just as a param default
// eslint-disable-next-line @typescript-eslint/ban-types
const LabelAndErrorFormGroup = <AdditionalInputComponentProps extends Record<string, unknown> = {}>(
  props: Props<AdditionalInputComponentProps>
): JSX.Element => {
  const {
    id,
    InputComponent,
    className,
    children,
    style,
    label,
    name,
    subLabel,
    subLabelPosition = 'above',
    inputRef,
    useFieldset = false,
    useLabelElementAsCaption = true,
    placeholder,
    required,
    validationError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validationErrorPlacement,
    validationType = 'popover',
    useKibbleStandardSizeStyle = false,
    ...other
  } = props;
  let labelComponent: JSX.Element | null = null;
  let childrenComponent: JSX.Element | null = null;

  const staticId = useId();

  if (label) {
    const htmlFor: string = getInputId(staticId, name, id);
    const labelProps: Record<string, unknown> = {
      className: 'control-label',
      id: useFieldset ? getFieldsetLegendId(staticId, name, id) : undefined,
      children: label,
    };

    if (useFieldset || !useLabelElementAsCaption) {
      labelProps.as = 'div';
    }

    if (useKibbleStandardSizeStyle) {
      labelComponent = <KibbleStandardSizeLabel htmlFor={htmlFor} {...labelProps} />;
    } else {
      labelComponent = <Label htmlFor={htmlFor} {...labelProps} />;
    }
  }

  const inputComponentProps: Record<string, unknown> = {
    ...other,
    ref: inputRef,
    name,
    required,
    'aria-required': required,
    id: getInputId(staticId, name, id),
    placeholder: placeholder || (typeof label === 'string' ? label : ''),
  };

  if (useFieldset && !InputComponent) {
    inputComponentProps['aria-label'] = label || '';
  }

  if (InputComponent) {
    childrenComponent = <InputComponent {...inputComponentProps} />;
  } else if (children) {
    // support function as child
    childrenComponent = children(inputComponentProps);
  }

  const formGroupProps: Record<string, unknown> = {
    className: classNames(
      {
        'has-error': validationError && validationType === 'popover',
      },
      className
    ),
    style,
  };

  if (useFieldset) {
    formGroupProps.as = 'fieldset';
    formGroupProps['aria-labelledby'] = getFieldsetLegendId(staticId, name, id);
  }

  return (
    <FormGroup {...formGroupProps}>
      {labelComponent}
      {subLabel && subLabelPosition === 'above' && !useKibbleStandardSizeStyle && (
        <SubLabel>{subLabel}</SubLabel>
      )}
      {subLabel && subLabelPosition === 'above' && useKibbleStandardSizeStyle && (
        <KibbleStandardSizeSubLabel>{subLabel}</KibbleStandardSizeSubLabel>
      )}
      {renderChildrenComponent(props, childrenComponent)}
      {subLabel &&
        subLabelPosition === 'below' &&
        (validationType === 'popover' || (validationType === 'inline' && !validationError)) &&
        !useKibbleStandardSizeStyle && <SubLabel>{subLabel}</SubLabel>}
      {subLabel &&
        subLabelPosition === 'below' &&
        (validationType === 'popover' || (validationType === 'inline' && !validationError)) &&
        useKibbleStandardSizeStyle && (
          <KibbleStandardSizeSubLabel>{subLabel}</KibbleStandardSizeSubLabel>
        )}
    </FormGroup>
  );
};

export default LabelAndErrorFormGroup;
