import captureError from '@rover/rsdk/src/modules/ErrorReporting/captureError/captureError';
import {
  isAndroid,
  isiOS,
  isMobileEmbedded,
  isReactNative,
} from '@rover/rsdk/src/modules/Network/userAgent';
import emitMetric from '@rover/utilities/emitMetric';

import EmbeddedEventStrategy from './embeddedEventStrategy';

/**
 * Given an event name and an inferred user agent type,
 * searches through the event strategy registry and returns
 * an instance of the first class to support the provided
 * arguments
 *
 * @param strategies
 * @param eventName
 * @returns { typeof EmbeddedEventStrategy | null }
 */
async function getStrategyForEvent(
  strategies: Array<typeof EmbeddedEventStrategy>,
  eventName: string
) {
  const StrategyCls = strategies.find((strategy: typeof EmbeddedEventStrategy) => {
    return strategy.handlesEvent(eventName, {
      isAndroid: isAndroid(),
      isiOS: isiOS(),
    });
  });

  if (!StrategyCls) {
    return null;
  }

  return new StrategyCls(captureError);
}

/**
 * Triggers an event on the host of the page, typically used to
 * communicate between embedded web and native applications.
 *
 * Uses a registry of strategy classes (see definition above),
 * each of which subclasses EmbeddedEventStrategy.
 *
 * To add support for a new client, subclass
 * EmbeddedEventStrategy for your situation and add it to the
 * registry where eventStrategies are defined above
 *
 * Each client that will be used by React Native must also be
 * explicitly registered in the RxN .flowconfig file
 *
 * @param strategies A list of available event emitting strategies
 * @param eventName The name of an event to trigger
 */
export async function triggerEmbeddedEvent(
  strategies: Array<typeof EmbeddedEventStrategy>,
  eventName: string
) {
  if (isReactNative() || !isMobileEmbedded()) {
    return;
  }
  const strategy = await getStrategyForEvent(strategies, eventName);

  if (!strategy) {
    captureError(
      new Error(
        `triggerEmbeddedEvent could not find an appropriate strategy for emitting the event ${eventName}`
      )
    );
    return;
  }

  strategy.triggerEvent(eventName);
  emitMetric('trigger_embedded_event', { event_name: eventName });
}
/**
 * EE: The following functions are broadly used. They have been
 * refactored to wrap triggerEmbeddedEvent and kept for legacy
 * purposes. Let's remove these functions Someday™!
 *
 * Please do not add new helper functions; prefer direct calls to triggerEmbeddedEvent instead
 */
export async function closeDialog(strategies: Array<typeof EmbeddedEventStrategy>) {
  await triggerEmbeddedEvent(strategies, 'dialog-closed');
}
export async function openReferralModal(strategies: Array<typeof EmbeddedEventStrategy>) {
  await triggerEmbeddedEvent(strategies, 'open-referral-modal');
}
