import moment, { Moment } from 'moment';

import DF from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';
import type { Time } from '@rover/types/src/datetime/Time';

export const EARLIEST_TIME = '00:00';
export const LATEST_TIME = '23:30';

export function parseTimeString(timeString?: string, timeFormat?: string): Moment {
  return moment(timeString, timeFormat || DF.TIME_SIMPLE);
}

export function formatMoment(timeMoment: Moment): string {
  return timeMoment.format(DF.TIME_SIMPLE);
}

export function isBeforeTime(startTime: Time, endTime: Time): boolean {
  return parseTimeString(startTime.value).isBefore(parseTimeString(endTime.value));
}

export const roundUpToClosestInterval = (m: Moment, interval: 15 | 30): Moment => {
  /*
  Round the time to the nearest interval, e.g.
  - for interval=15, 10.17 is going to be rounded down to 10.15
  - for interval=30, 10.17 is going to be rounded up to 10.30.
  */
  const remainder = m.minutes() % interval;
  if (remainder >= Math.round(interval / 2)) {
    return m.add(interval - remainder, 'minutes');
  }
  return m.subtract(remainder, 'minutes');
};

const is12hClockSpecific = (input: string): string => {
  const searchMap = [
    { pattern: 'am', result: 'am' },
    { pattern: 'pm', result: 'pm' },
    { pattern: 'a', result: 'am' },
    { pattern: 'p', result: 'pm' },
  ];
  const lowercaseInput = input.toLowerCase();
  for (const searchPattern of searchMap) {
    if (lowercaseInput.search(searchPattern.pattern) !== -1) return searchPattern.result;
  }
  return '';
};

const defaultToWorkingHoursRange = (hour: number): string => {
  /*
  Set a default range for suggestions in case that the user hasn't been specific enough.
  The range for most common working hours is set to 8 AM - 7 PM for the moment.
  */
  const lowerAM = 8; // This represents the lower bound for what we want to consider AM
  const upperPM = 12; // Any hour greater than 12 is obviously PM
  return hour >= lowerAM && hour < upperPM ? 'am' : 'pm';
};

export const extractClosestTime = (
  timeString: string,
  interval?: 15 | 30,
  resultFormat?: string
): string => {
  /*
  Parse and return a time in the specified format (or 12h by default) from a given input string,
  mimicking the behaviour of the GCalendar TimePicker.
  If the optional argument 'interval' is provided, we'll round the result to the nearest one.
  If the input is invalid, we'll return an empty string.
  */

  // If the input contains no digits or more than four (HH:MM), we consider it invalid
  const numbers = timeString.match(/\d/g)?.join('');
  if (numbers == null || numbers.length > 4) {
    return '';
  }

  const timeFormatMapping = ['h', 'hh', 'hmm', 'hhmm'];
  let time = moment(numbers, timeFormatMapping[numbers.length - 1]);
  if (!time.isValid()) {
    return '';
  }

  // Once we've reached this section, we know that the time is valid.
  // The next step is to determine if the format is in 12/24h clock
  // If the hour is greater than 12, it's clear that it's in 24h, and it's PM
  if (time.hour() > 0 && time.hour() <= 12) {
    // If not, then we need to check if the user was specific enough
    let isIn12hFormat = is12hClockSpecific(timeString);
    // If they weren't, we'll just have to guess based on most common hours
    if (isIn12hFormat === '') isIn12hFormat = defaultToWorkingHoursRange(time.hour());
    // Since 'moment' works in 24h clock format, we need to make some adjustments to convert our input
    if (isIn12hFormat === 'pm' && time.hour() !== 12) time.add(12, 'hour');
    if (isIn12hFormat === 'am' && time.hour() === 12) time.add(-12, 'hour');
  }

  if (interval) time = roundUpToClosestInterval(time, interval);

  // if resultFormat isn't defined or isn't 'h:mm A' (12h) or 'HH:mm' (24h), default to 12h
  const validFormat = resultFormat && ['h:mm A', 'HH:mm'].includes(resultFormat);
  const format = validFormat ? resultFormat : 'h:mm A';
  return time.format(format);
};
