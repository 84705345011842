import Connect from './Connect';
import Provider from './Provider';

export { default as Provider } from './Provider';
export { default as Connect } from './Connect';
export type { ContainerProps } from './Connect';

export default {
  Provider,
  Connect,
};
