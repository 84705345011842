import { UserInformation } from '@rover/rsdk/src/apiClient/latest';

export default function userFactory(opts?: Partial<UserInformation>): UserInformation {
  return {
    opk: '',
    apiUrl: '',
    email: '',
    emailHash: '',
    firstName: '',
    lastName: '',
    phone: '',
    isStaff: false,
    postalCode: '',
    hasDogs: false,
    isActiveServiceProvider: false,
    listsAllServices: false,
    shouldPromptForPets: false,
    slug: '',
    providerGroupSlug: '',
    smallPhotoUrl: '',
    timeZone: '',
    unreadMessageCount: 0,
    showAdminToolbar: false,
    isNewCustomer: false,
    isSsuMigrated: false,
    isIdentityVerificationRequired: false,
    isActiveSssuApplicantWithSelectedServices: false,
    wasEverServiceProvider: false,
    userMode: '',
    ...opts,
  };
}
