import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';

import { PopoverEvent } from './popover.constants';

type StyledTargetWrapperProps = {
  event: PopoverEvent;
  display?: 'inline' | 'inline-block' | 'block';
};
export const StyledTargetWrapper = styled.div<StyledTargetWrapperProps>`
  width: 100%;
  display: ${(props) => props.display};
  cursor: ${(props) => (props.event === PopoverEvent.CLICK ? 'pointer' : 'default')};
`;
export type Props = StyledTargetWrapperProps & {
  innerRef?: (arg0: HTMLDivElement | null | undefined) => void;
};

const TargetWrapper = ({ innerRef, ...other }: Props): ReactElement => (
  <StyledTargetWrapper {...other} ref={innerRef} />
);

export default TargetWrapper;
