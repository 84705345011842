import React from 'react';
import { MessageDescriptor } from '@lingui/core';

import { Box, Text } from '@rover/kibble/core';
import { BoxCommonProps } from '@rover/kibble/core/Box/Box.common';
import { TextNodes } from '@rover/kibble/core/Text/Text';
import { TextCommonProps } from '@rover/kibble/core/Text/Text.common';
import { CLASS_NAMES } from '@rover/react-lib/src/constants/classNames.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

type FormBasicValidationErrorProps = {
  errorMessageArialabel?: React.ComponentProps<TextNodes>['aria-label'];
  errorMessageArialive?: React.ComponentProps<TextNodes>['aria-live'];
  errorMessage: MessageDescriptor | string | JSX.Element;
  boxProps?: BoxCommonProps;
  textProps?: Omit<TextCommonProps, 'children'>;
  id?: string;
  ariaRole?: string;
};

const FormBasicValidationError = ({
  errorMessageArialabel,
  errorMessageArialive,
  errorMessage,
  ariaRole,
  boxProps,
  textProps,
  id = '',
}: FormBasicValidationErrorProps): JSX.Element => {
  const { i18n } = useI18n();
  return (
    <Box className={CLASS_NAMES.FORM_ERROR_CLASSNAME} {...boxProps} role={ariaRole}>
      <Text
        aria-label={errorMessageArialabel}
        aria-live={errorMessageArialive}
        size="100"
        textColor="error"
        data-testid="errorMessage"
        id={id}
        {...textProps}
      >
        {React.isValidElement(errorMessage) || typeof errorMessage === 'string'
          ? errorMessage
          : i18n._(errorMessage as MessageDescriptor)}
      </Text>
    </Box>
  );
};

export default FormBasicValidationError;
