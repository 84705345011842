import type { KeyboardEvent, ReactElement, ReactNode } from 'react';
import React, { Component } from 'react';
import { Arrow, Manager, Popper, Target } from 'react-popper';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Color, DSTokenMap } from '@rover/kibble/styles';
import { onSpaceOrEnterKeyPress, onSpecifiedKeyPress } from '@rover/react-lib/src/utils/input';
import { isHoverable } from '@rover/rsdk/src/modules/Network/userAgent';

import { PopoverEvent, PopoverPlacement } from './popover.constants';
import StyledPopover, { TRANSITION_DURATION } from './StyledPopover';
import TargetWrapper from './TargetWrapper';

export const MOUSE_LEAVE_DELAY = 100;
type RenderProp = (arg0: { onClose: () => void }) => ReactNode;
export type Props = {
  id?: string;
  children: ReactNode;
  className: string;
  content: ReactNode | RenderProp;
  open?: boolean;
  placement: PopoverPlacement;
  event: PopoverEvent;
  backgroundColor: Color;
  borderColor: Color;
  display: 'inline' | 'inline-block' | 'block';
  showArrow: boolean;
  toggleOnFocusOrBlur?: boolean;
  toggleOnSpaceOrEnter?: boolean;
  onChange?: (arg0: boolean) => void;
  role?: string;
  maxWidth: string;
};
type State = {
  open: boolean;
};
export type PopperObj = {
  popper: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getArrowStyle: (...args: any[]) => any;
  };
};
type PopoverArrowContextProps = Props & {
  innerRef: (arg0: HTMLDivElement | null | undefined) => void;
  transitionState: string;
};

const PopoverArrowContext = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { innerRef, content, ...other }: PopoverArrowContextProps,
  { popper }: PopperObj
): ReactElement => (
  // @ts-expect-error fixing the odd TS errors from styled-components on Popover is tricky and not worth fixing because we need to update the Popover lib anyway
  <StyledPopover arrowStyle={popper.getArrowStyle()} {...other} ref={innerRef} />
);

PopoverArrowContext.contextTypes = {
  popper: PropTypes.object.isRequired,
};
export const ContentWrapper = styled.div<Record<string, unknown>>`
  background: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  /* this max-width helps prevent Popovers from being wider than the screen, which can trigger a resize loop */
  max-width: calc(100vw - 60px);
`;
export const StyledArrow = styled(Arrow)<Record<string, unknown>>`
  position: absolute;
`;
export default class Popover extends Component<Props, State> {
  removeListener: (() => void) | null | undefined;

  timeout: ReturnType<typeof setTimeout> | null | undefined;

  popover: HTMLElement | null | undefined;

  static defaultProps = {
    display: 'inline',
    className: '',
    event: PopoverEvent.HOVER,
    placement: PopoverPlacement.BOTTOM,
    backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY,
    borderColor: DSTokenMap.BORDER_COLOR_PRIMARY,
    showArrow: true,
    toggleOnFocusOrBlur: false,
    toggleOnSpaceOrEnter: false,
    maxWidth: null,
  };

  state = {
    open: false,
  };

  componentDidUpdate(): void {
    if (!this.isOpen() && this.removeListener) {
      this.removeListener();
      this.removeListener = undefined;
    }
  }

  componentWillUnmount(): void {
    this.clearTimeout && this.clearTimeout();

    if (this.removeListener) {
      this.removeListener();
    }
  }

  static Event = PopoverEvent;

  static Placement = PopoverPlacement;

  handleOpen = (): void => {
    const { event, onChange } = this.props;

    if (event !== PopoverEvent.NONE) {
      if (onChange) onChange(true);
      this.setState(() => ({
        open: true,
      }));
    }
  };

  handleClose = (): void => {
    const { event, onChange } = this.props;

    if (event !== PopoverEvent.NONE) {
      if (onChange) onChange(false);
      this.setState(() => ({
        open: false,
      }));
    }
  };

  handleToggle = (): void => {
    const { event, onChange } = this.props;

    if (event !== PopoverEvent.NONE) {
      this.setState((state) => {
        if (onChange) onChange(!state.open);
        return {
          open: !state.open,
        };
      });
    }
  };

  isOpen = (): boolean | undefined =>
    this.props.event === PopoverEvent.NONE ? this.props.open : this.state.open;

  clearTimeout = (): void => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  };

  handleFocusOutsideOfTooltip = (event: FocusEvent): void => {
    // if keyboard focus is transferred to an element outside the tooltip, close it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.popover && !this.popover.contains(event.relatedTarget as any)) {
      this.handleClose();
      if (process.env.JS_ENV_CLIENT)
        document.removeEventListener('focusout', this.handleFocusOutsideOfTooltip, false);
    }
  };

  handleOutsideClick = (event: Event): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.popover && !this.popover.contains(event.target as any)) {
      this.handleClose();
    }
  };

  handleClick = (): void => {
    const { event } = this.props;

    if (event === PopoverEvent.CLICK || !isHoverable()) {
      this.handleToggle();

      if (!this.isOpen()) {
        setTimeout(() => {
          // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
          document.addEventListener('click', this.handleOutsideClick, false);
        }, 0);

        this.removeListener = (): void =>
          // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
          document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }
  };

  handleKeyDown = (e: KeyboardEvent): void => {
    // allow Esc to close the popover if open
    if (this.isOpen()) {
      onSpecifiedKeyPress(e, ['Escape'], this.handleToggle);
    }
  };

  handleMouseEnter = (): void => {
    const { event } = this.props;

    if (event === PopoverEvent.HOVER && isHoverable()) {
      this.clearTimeout();
      this.handleOpen();
    }
  };

  handleMouseLeave = (): void => {
    const { event } = this.props;

    if (event === PopoverEvent.HOVER && isHoverable()) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.handleClose();
        this.timeout = undefined;
      }, MOUSE_LEAVE_DELAY);
    }
  };

  renderContent = (): ReactNode => {
    const { content } = this.props;

    if (typeof content === 'function') {
      return content({
        onClose: this.handleClose,
      });
    }

    return content;
  };

  render(): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, placement, event, open, display, ...other } = this.props;
    const eventHandlers: Record<string, unknown> = {
      onClick: this.handleClick,
      onKeyDown: this.handleKeyDown,
      // we need to use onKeyDown to catch Esc as onKeyPress doesn't detect it
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    };

    if (this.props.toggleOnSpaceOrEnter) {
      eventHandlers.onKeyPress = (e: KeyboardEvent): void =>
        onSpaceOrEnterKeyPress(e, this.handleToggle);
    }

    if (this.props.toggleOnFocusOrBlur) {
      eventHandlers.onFocus = (): void => {
        if (!this.state.open) {
          this.handleToggle();
          // add a document-level blur handler which will close the tooltip if focus goes outside of it
          // (we use focusout instead of blur because blur doesn't bubble up from the trigger element)
          if (process.env.JS_ENV_CLIENT)
            document.addEventListener('focusout', this.handleFocusOutsideOfTooltip, false);
        }
      };
    }

    return (
      <Manager tag={display === 'inline' ? 'span' : 'div'}>
        <Target
          {...eventHandlers}
          component={TargetWrapper}
          event={event}
          display={display}
          data-testid="styled-wrapper"
          id={this.props.id}
        >
          {children}
        </Target>
        <Transition in={this.isOpen()} timeout={TRANSITION_DURATION} unmountOnExit>
          {(state): JSX.Element => (
            <Popper
              // @ts-expect-error
              placement={placement.toString()}
              modifiers={{
                offset: {
                  offset: -10,
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            >
              {({
                popperProps: { ref, 'data-x-out-of-boundaries': dataXOutOfBoundaries, ...rest },
              }): JSX.Element => {
                const isOutOfBoundaries = typeof dataXOutOfBoundaries !== 'undefined';
                return (
                  <PopoverArrowContext
                    // @ts-expect-error
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    placement={placement}
                    transitionState={state}
                    innerRef={(p): void => {
                      if (p) {
                        this.popover = p;
                        ref(p);
                      }
                    }}
                    isOutOfBoundaries={isOutOfBoundaries}
                    {...rest}
                    {...other}
                  >
                    <ContentWrapper>{this.renderContent()}</ContentWrapper>
                    <StyledArrow />
                  </PopoverArrowContext>
                );
              }}
            </Popper>
          )}
        </Transition>
      </Manager>
    );
  }
} // If we export this directly on top when we instantiate the variable, styleguide would endup using
// this component's name as displayname in the styleguide example.
// see warning section for more info: https://react-styleguidist.js.org/docs/components.html#default-vs-named-exports

export { PopoverArrowContext };
