import APIError from '@rover/react-lib/src/apis/APIError';
import { get } from '@rover/react-lib/src/apis/currentUser.api';
import logout from '@rover/react-lib/src/apis/logout.api';
import fetchCurrentUserResponseFactory from '@rover/react-lib/src/factories/fetchCurrentUserResponseFactory';
import type {
  ActionCreator,
  Duck,
  DuckOptions,
  EffectCreator,
  SelectorCreator,
} from '@rover/react-lib/src/lib/redux-duck';
import { createDuck } from '@rover/react-lib/src/lib/redux-duck';
import {
  CurrentUser,
  CurrentUserViewSchema,
  UserInformation,
} from '@rover/rsdk/src/apiClient/latest';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import { getCurrentScope } from '@rover/rsdk/src/modules/ErrorReporting/sentry';
import ObservabilityMetadata from '@rover/rsdk/src/modules/ObservabilityMetadata';
import type { UtmParams } from '@rover/types/src/ImagePicker';

import generateActions from './generateActions';

export type State = {
  errorMessage: string;
  loading: boolean;
  logoutPending: boolean;
  fetchCurrentUserResponse: CurrentUserViewSchema;
};

type Actions = {
  fetchCurrentUserPending: ActionCreator<void>;
  fetchCurrentUserSuccess: ActionCreator<CurrentUserViewSchema>;
  fetchCurrentUserFailure: ActionCreator<string>;
  logoutCurrentUserPending: ActionCreator<void>;
  logoutCurrentUserSuccess: ActionCreator<CurrentUserViewSchema>;
  logoutCurrentUserFailure: ActionCreator<string>;
};

type Effects = {
  fetchCurrentUser: EffectCreator<void>;
  logoutCurrentUser: EffectCreator<{
    nextLocation: string;
    ref?: string;
  }>;
  initSentryUser: EffectCreator<void>;
};

type Selectors = {
  getResponse: SelectorCreator<CurrentUserViewSchema>;
  getCurrentUser: SelectorCreator<UserInformation>;
  getRealUser: SelectorCreator<UserInformation>;
  isLoading: SelectorCreator<boolean>;
  getErrorMessage: SelectorCreator<string>;
  getApiUrl: SelectorCreator<string>;
  getOpk: SelectorCreator<string>;
  isAuthenticated: SelectorCreator<boolean>;
  isImpersonating: SelectorCreator<boolean>;
  getPeopleUrl: SelectorCreator<string>;
  isNewCustomer: SelectorCreator<boolean>;
  getPostalCode: SelectorCreator<string>;
  getUtmParams: SelectorCreator<UtmParams>;
  getFirstHttpReferrer: SelectorCreator<string | null>;
  getIsSsuMigrated: SelectorCreator<boolean>;
  getIsActiveServiceProvider: SelectorCreator<boolean>;
  getIsIdentityVerificationRequired: SelectorCreator<boolean>;
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'currentUser',
  initialState: {
    errorMessage: '',
    loading: true,
    logoutPending: false,
    fetchCurrentUserResponse: fetchCurrentUserResponseFactory(),
  },
  actions: { ...generateActions('fetchCurrentUser'), ...generateActions('logoutCurrentUser') },
  effects: {
    fetchCurrentUser:
      () =>
      async (
        dispatch,
        getState,
        { fetchCurrentUserPending, fetchCurrentUserSuccess, fetchCurrentUserFailure },
        { initSentryUser }
      ) => {
        dispatch(fetchCurrentUserPending());

        try {
          const fetchCurrentUserResponse: CurrentUserViewSchema = await get();
          // this is the earliest and most common spot to set this ObservabilityMetadata prop
          ObservabilityMetadata.set(
            'userOPK',
            (fetchCurrentUserResponse as CurrentUser)?.currentUser.opk
          );
          dispatch(fetchCurrentUserSuccess(fetchCurrentUserResponse));
          dispatch(initSentryUser());
        } catch (e) {
          if ((e as APIError).status === 404) {
            dispatch(fetchCurrentUserSuccess(fetchCurrentUserResponseFactory()));
          } else {
            dispatch(fetchCurrentUserFailure((e as APIError).message));
            captureError(e as APIError);
          }
        }
      },
    logoutCurrentUser:
      ({ nextLocation, ref }) =>
      async (
        // Only call this on the client, never the server
        dispatch,
        getState,
        { logoutCurrentUserPending, logoutCurrentUserSuccess, logoutCurrentUserFailure }
      ) => {
        dispatch(logoutCurrentUserPending());

        try {
          logout({
            nextLocation,
            ref,
            beforeNavCallback: () =>
              dispatch(logoutCurrentUserSuccess(fetchCurrentUserResponseFactory())),
          });
        } catch (e) {
          dispatch(logoutCurrentUserFailure((e as APIError).message));
          captureError(e as APIError);
        }
      },
    initSentryUser:
      () =>
      async (dispatch, getState, actions, effects, { getOpk }) => {
        getCurrentScope().setUser({
          id: getOpk(getState()),
        });
      },
  },
  selectors: {
    getResponse: (getState, createSelector) =>
      createSelector([getState], (x) => x.fetchCurrentUserResponse),
    getCurrentUser: (getState, createSelector, { getResponse }) =>
      createSelector([getResponse], (x) => x.currentUser),
    getRealUser: (getState, createSelector, { getResponse }) =>
      createSelector([getResponse], (x) => x.realUser),
    isLoading: (getState, createSelector) => createSelector([getState], (x) => x.loading),
    getErrorMessage: (getState, createSelector) =>
      createSelector([getState], (x) => x.errorMessage),
    getApiUrl: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.apiUrl),
    getOpk: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.opk),
    isAuthenticated: (getState, createSelector, { getResponse }) =>
      createSelector([getResponse], (x) => x.authenticated),
    isImpersonating: (getState, createSelector, { getRealUser }) =>
      createSelector([getRealUser], (x) => !!x?.opk.length),
    getPeopleUrl: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => `/admin/people/person/?slug=${x.slug}`),
    isNewCustomer: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.isNewCustomer),
    getPostalCode: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.postalCode),
    getUtmParams: (getState, createSelector, { getResponse }) =>
      createSelector([getResponse], (x) => x.session?.utmParameters ?? {}),
    getFirstHttpReferrer: (getState, createSelector, { getResponse }) =>
      createSelector([getResponse], (x) => x.session?.firstHttpReferrer || null),
    getIsSsuMigrated: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.isSsuMigrated),
    getIsActiveServiceProvider: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.isActiveServiceProvider),
    getIsIdentityVerificationRequired: (getState, createSelector, { getCurrentUser }) =>
      createSelector([getCurrentUser], (x) => x.isIdentityVerificationRequired),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options) as Duck<
  State,
  Actions,
  Effects,
  Selectors
>;
