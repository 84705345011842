import type moment from 'moment';

import { EXTERNAL_FORMAT_MAP } from '@rover/shared/js/constants/i18n/datetime/datetimeInjected';

declare global {
  interface Window {
    moment: typeof moment;
  }
}

if (typeof window.moment === 'undefined') {
  // eslint-disable-next-line
  const moment = require('moment');

  window.moment = moment;
  const oldMomentFormat = moment.prototype.format;

  // eslint-disable-next-line func-names
  moment.prototype.format = function (format, ...args) {
    // map external format to existing format
    // this helps to handle external library
    const i18nFormat = EXTERNAL_FORMAT_MAP[format] || format;
    return oldMomentFormat.apply(this, [i18nFormat, ...args]);
  };
}

export default window.moment;
